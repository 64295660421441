import React, {useState, useEffect} from "react"
import {Row, Col, Modal, Checkbox, Input, message} from 'antd';
import {upPdf, upBh} from "../../../api/OnlineInvoicing"
import Button from "antd/es/button";
import {showLoading, hideLoading} from "../../../common/Xjkjload"

const ShenHei = (props) => {
    let {visible, onOk, onCancel, records} = props
    const [checkedTg, setCheckedTg] = useState(false)
    const [checkedBh, setCheckedBh] = useState(false)
    const [values, setValues] = useState("")
    const [fileList, setFileList] = useState([])
    const onChangeTg = (e) => {
        setCheckedTg(e.target.checked)
        if (e.target.checked) {
            setCheckedBh(false)
        }
    }
    const onChangeBh = (e) => {
        setCheckedBh(e.target.checked)
        if (e.target.checked) {
            setCheckedTg(false)
        }
    }
    const handleExcel = (e) => {
        setFileList(e.target.files)
    }
    const onValue = (e) => {
        setValues(e.target.value)
    }
    const upOkFun = () => {
        if (!checkedTg && !checkedBh) {
            message.warn("请确定是否通过")
        } else {
            if (checkedTg) {
                if (fileList.length == 0) {
                    message.warn("请选择文件")
                } else {
                    let obj = new FormData()
                    obj.append("businessId", records.merchantInvoiceId)
                    obj.append("file", fileList[0])
                    showLoading()
                    upPdf(obj).then(res => {
                        hideLoading()
                        if (res.data.success) {
                            message.success(res.data.message)
                            let obj = new FormData()
                            obj.append("merchantInvoiceId", records.merchantInvoiceId)
                            obj.append("invoiceStatus", "3")
                            obj.append("invoicePhotoId", res.data.data.fileId)
                            upBh(obj)
                        } else {
                            message.warn(res.data.message)
                        }
                        onCancel()
                    }).catch(() => {
                        hideLoading()
                    })
                }
            } else {
                if (!values) {
                    message.warn("请输入驳回理由")
                } else {

                    let obj = new FormData()
                    obj.append("merchantInvoiceId", records.merchantInvoiceId)
                    obj.append("invoiceStatus", "2")
                    obj.append("rejectReason", values)
                    showLoading()
                    upBh(obj).then(res => {
                        hideLoading()
                        if (res.data.success) {
                            message.success(res.data.message)
                        } else {
                            message.warn(res.data.message)
                        }
                        onCancel()
                    }).catch(() => {
                        hideLoading()
                    })
                }

            }
        }
    }
    return <>
        <Modal title="审核" visible={visible} onOk={onOk} onCancel={onCancel} width={500}
               footer={<Button type="primary" onClick={upOkFun}>确定</Button>}>
            <Row>
                <Col>
                    <Checkbox onChange={onChangeTg} style={{width: "120px"}} checked={checkedTg}>通过</Checkbox>
                </Col>
                <Col>
                    <Checkbox onChange={onChangeBh} style={{width: "120px"}} checked={checkedBh}>驳回</Checkbox>
                </Col>
            </Row>

            {
                checkedTg ? (
                    <Input type="file" accept="image/*,.pdf,.gz,.zip,.rar" onChange={handleExcel}></Input>) : ""
            }
            {
                checkedBh ? (<Input placeholder="请输入驳回理由" onChange={onValue}></Input>) : ""
            }
        </Modal>
    </>
}
export default ShenHei
