import React, {useState} from 'react';
import { Upload, message, Button, Table } from 'antd';
import { UploadOutlined, FileTextOutlined, DownloadOutlined } from '@ant-design/icons';
import style from './BindingContract.module.less'

const BindingContract =()=>{
    const columns = [
        {
            title:'上传时间',
            dataIndex: 'uploadTime'
        },
        {
            title:'更新时间',
            dataIndex: 'updateTime'
        },
        {
            title:'上传人',
            dataIndex: 'uploadPer'
        },
        {
            title:'状态',
            dataIndex: 'state'
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            render: () => (
                <>
                    <Button icon={<FileTextOutlined />} type="link"></Button>
                    <Button icon={<DownloadOutlined />} type="link"></Button>
                </>
            )
          }
    ];
    const [data,setData] = useState([
            {
                key: '1',
                uploadTime: '2021',
                updateTime: '2022',
                uploadPer: '吴大飞',
                state:'审核通过'
            }
        ]
    )
    return (
        <div className={style.BindingContract_body}>
            <Upload name="file" >
                <Button icon={<UploadOutlined />}>上传合同</Button>
            </Upload>
            <Table columns={columns} dataSource={data} style={{marginTop: '20px'}}/>
        </div>
    )
}
export default BindingContract