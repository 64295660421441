import React, {useState} from 'react';
import {Form, Input, InputNumber, Modal} from 'antd'
import './TaskSettlementImport.less'

const TaskSettlementConfirm = (props) => {
    const [value, setValue] = useState(1);
    let {visible, onOk, onCancel, excelPersonNum, excelTotalAmount} = props
    const [form] = Form.useForm();
    const onSure = () => {
        form.validateFields().then(value => {
            onOk()
        })
    }
    return (
        <Modal title="验证金额" visible={visible} onOk={onSure} onCancel={onCancel} okText='确定' cancelText="取消">
            <Form
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="结算笔数"
                    name="personNum"
                    rules={[
                        () => ({
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.reject("请输入结算笔数")
                                } else {
                                    if (value === excelPersonNum) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject("结算笔数不匹配")
                                    }
                                }
                            }
                        })
                    ]}
                    wrapperCol={{offset: 1, span: 22}}
                >
                    <InputNumber min={1} style={{width: '150px'}}/>
                </Form.Item>
                <Form.Item
                    label="结算金额"
                    name="totalAmount"
                    rules={[
                        () => ({
                            validator(rule, value) {
                                if (!value) {
                                    return Promise.reject("请输入结算金额")
                                } else {
                                    if (Number(value) == Number(excelTotalAmount).toFixed(2)) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject("结算金额不匹配")
                                    }
                                }
                            }
                        })
                    ]}
                    wrapperCol={{offset: 1, span: 22}}
                >
                    <Input style={{width: '60%'}} addonAfter="元"/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default TaskSettlementConfirm