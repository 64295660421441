import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Table, Tabs} from 'antd';
import 'moment/locale/zh-cn';
import MultiImport from './components/MultiImport'
import {taskSettlementListInfo} from '../../api/TaskSettlementList'
import qs from 'qs'
import {DownloadOutlined} from '@ant-design/icons';

const TaskSettlementList = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [data, setData] = useState([])
    const {TabPane} = Tabs;
    const [isVisible, setIsVisible] = useState(false)
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const [selectData, setSelectData] = useState([])
    const [key, setKey] = useState(1)
    // 下载模板
    const getServiceConfirm = () => {
        let url = 'https://www.027xjkj.com/服务费用确认函模板.docx'
        window.location.href = url
    }
    const getServiceServiceSettle = () => {
        let url = 'https://www.027xjkj.com/服务费用结算清单模板.xls'
        window.location.href = url
    }
    const callback = (key) => {
        setKey(key)
        getList(key)
        setSelectedRowKeys([])
    }
    const multiImport = () => {
        setIsVisible(true)
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRows)
        }
    };
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const downLoadSettlement = (id) => {
        let values = {fileId: id};
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);
    }
    const downLoadService = (id) => {
        let values = {fileId: id};
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);
    }
    const getList = (key, page = 1, limit = 10) => {
        let merchantServeId = window.localStorage.getItem('merchantServeId')
        let param = {
            page: page,
            limit: limit,
            merchantServeId: merchantServeId,
            batchStatus: key || 1
        }
        taskSettlementListInfo(param).then(res => {
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setData(res.data.data)
        })
    }
    const pageOnChange = (page, pageSize) => {
        getList(key, page, pageSize)
    }
    useEffect(() => {
        getList(1)
    }, [])
    const columns = [
        {
            title: 'ID',
            dataIndex: 'taskBalanceId',
            align: 'center'
        },
        {
            title: '确认函',
            dataIndex: 'age',
            render: (data, row) => {
                return (
                    <div>
                        {row.settlementId ? <DownloadOutlined onClick={() => downLoadSettlement(row.settlementId)}
                                                              style={{color: 'green'}}/> : ''}
                    </div>
                )
            },
            align: 'center'
        },
        {
            title: '结算清单',
            dataIndex: 'serviceFeeId',
            render: (data, row) => {
                return (
                    <div>
                        {row.serviceFeeId ? <DownloadOutlined onClick={() => downLoadService(row.serviceFeeId)}
                                                              style={{color: 'green'}}/> : ''}
                    </div>
                )
            },
            align: 'center'
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            align: 'center'
        },
        {
            title: '成功金额',
            dataIndex: 'succcessAmount',
            align: 'center'
        },
        {
            title: '提交时间',
            dataIndex: 'settlementSubmitTime',
            align: 'center'
        },
        {
            title: '结算状态',
            dataIndex: 'paymentStatusName',
            align: 'center'
        },
        {
            title: '审核状态',
            dataIndex: 'batchStatusName',
            align: 'center'
        }
    ];
    return (
        <div>
            <Row style={{marginBottom: 50}}>
                <Col> <Button type="link" onClick={getServiceConfirm}> 下载服务费用确认函模板.docx </Button></Col>
                <Col> <Button type="link"
                              onClick={getServiceServiceSettle}> 下载服务费用结算清单模板.xls </Button></Col>
            </Row>
            <Tabs onChange={callback} type="card" destroyInactiveTabPane>
                <TabPane tab="等待用户上传" key="1">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.records} pagination={{
                        total: data.total,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: data.current
                    }}/>
                    <Row style={{marginTop: 50}}>
                        <Col> <Button type="primary" onClick={multiImport}
                                      disabled={ptCheckerFlag == 1 ? true : (selectedRowKeys.length === 0)}> 批量上传业务清单 </Button></Col>
                    </Row>
                </TabPane>
                <TabPane tab="拒绝（需要重新上传）" key="3">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.records} pagination={{
                        total: data.total,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: data.current
                    }}/>
                    <Row style={{marginTop: 50}}>
                        <Col> <Button type="primary" onClick={multiImport}
                                      disabled={ptCheckerFlag == 1 ? true : (selectedRowKeys.length === 0)}> 批量上传业务清单 </Button></Col>
                    </Row>
                </TabPane>
                <TabPane tab="正在审核中" key="2">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.records} pagination={{
                        total: data.total,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: data.current
                    }}/>
                </TabPane>
                <TabPane tab="已审核通过" key="4">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.records} pagination={{
                        total: data.total,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: data.current
                    }}/>
                </TabPane>
            </Tabs>
            {
                isVisible ? (<MultiImport isVisible={isVisible} setIsVisible={setIsVisible} getList={getList}
                                          selectedRowKeys={selectedRowKeys}
                                          setSelectedRowKeys={setSelectedRowKeys}/>) : ''
            }
        </div>
    )
}
export default TaskSettlementList