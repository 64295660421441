import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Descriptions, Form, Input, Select, DatePicker, Space, Table, Tag, message} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {getDataList, getExport} from "../../api/DistributionList"
import {downloadPdf} from "../../api/TaskSettlement"
import moment from 'moment';
import {
    PaperClipOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import {SpaceContext} from 'antd/lib/space';
import qs from 'qs'
import {showLoading, hideLoading} from "../../common/Xjkjload"
import {settleDetailInfo} from "../../api/TaskSettlement";

const DistributionList = () => {
    const {Option} = Select
    const {RangePicker} = DatePicker;
    const [dataList, setDataList] = useState([])
    const [form] = Form.useForm();
    const [valueType, setValueType] = useState("4")
    const [time, setTime] = useState([undefined, undefined])
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const [times, setTimes] = useState([undefined, undefined])
    const dateFormat = 'YYYY-MM-DD' || undefined;
    let merchantServeId = window.localStorage.getItem("merchantServeId");
    const onFinish = async (page = 1, limit = 10) => {
        let values = await form.validateFields();
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = merchantServeId
        values.page = page;
        values.limit = limit;
        if (valueType == 4) {
            values.paymentStatus = ""
        } else {
            values.paymentStatus = valueType
        }
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const setTimesFun = (value) => {
        if (value == 2) {
            let startDate = moment().startOf('month').format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 1) {
            let startDate = moment().day(moment().day() - 6).format('YYYY-MM-DD') // 当前时间往前推一周的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 3) {
            let startDate = moment().subtract(1, 'quarters').format('YYYY-MM-DD ') // 当前时间往前推三个月的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const setTimesFuns = (value) => {
        if (value) {
            var year = value.split('-')[0];
            var month = value.split('-')[1];
            console.log(year + '-' + month)
            let startDate = moment(value).startOf('month').format('YYYY-MM-DD')
            let endDate = moment(value).endOf('month').format('YYYY-MM-DD')
            console.log(startDate + '-' + endDate)
            setTimes([startDate, endDate])
        } else {
            setTimes([undefined, undefined])
        }
    }
    const onFinishs = () => {
        onFinish()
    }
    const onExportFun = async () => {
        let values = {};
        values.startDate = times[0];
        values.endDate = times[1];
        values.merchantServeId = merchantServeId
        if (valueType == 4) {
            values.paymentStatus = ""
        } else {
            values.paymentStatus = valueType
        }
        window.open(`/api/taskSettlementFlow/exportInfoExcel?${qs.stringify(values)}`, {
            method: 'GET',
            Authorization: window.localStorage.getItem("token")
        });
    }
    useEffect(() => {
        let obj = {page: 1, limit: 10, merchantServeId}
        let newValues = qs.stringify(obj)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    }, [])

    const selects = () => {
        var data = new Date();
        var year = data.getFullYear();
        var month = data.getMonth() + 1;
        var list = [];
        for (var i = 0; i < 12; i++) {
            list.push((<Option key={i} value={year.toString() + '-' + month.toString()}>
                {year}年{month}月
            </Option>))
            month = month - 1;
            if (month == 0) {
                year = year - 1;
                month = 12;
            }
        }
        return list;
    }

    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
        console.log(page, pageSize)
    }
    const onChange2 = async (value) => {
        setValueType(value)
        let values = await form.validateFields();
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = merchantServeId
        if (value == 4) {
            values.paymentStatus = ''
        } else {
            values.paymentStatus = value
        }
        values.page = 1;
        values.limit = 10;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    }
    const downloadPdfByUrl = async (id) => {
        showLoading()
        let param = {
            balanceDetailId: id
        }
        downloadPdf(param).then(
            res => {
                hideLoading()
                if (res.data.code === 200) {
                    let pdfUrl = res.data.data.pdfUrl;
                    if (pdfUrl === '') {
                        message.warn("暂无电子回单，请次日15:00后重试");
                        return;
                    }
                    if (res.data.data.merchantType === 'huiji' || res.data.data.merchantType === 'lingtu') {
                        if (res.data.data.pdfUrl != null) {
                            window.open(pdfUrl)
                        } else {
                            message.warn("暂无电子回单，请次日15:00后重试");
                        }
                    } else if (res.data.data.merchantType === 'cmb') {
                        if (res.data.data.payType === 'replace') {
                            openCMBPdf(pdfUrl);
                        } else {
                            const fileName = res.data.data.name + ' 电子回单' + res.data.data.balanceDetailId + '.pdf'        //附件名包含附件类型
                            if ('download' in document.createElement('a')) {
                                console.log('下载成功')
                                let data = base64ToBlob(pdfUrl)
                                const link = document.createElement('a')
                                link.download = fileName
                                link.style.display = 'none'
                                link.href = URL.createObjectURL(data)
                                document.body.appendChild(link)
                                link.click()
                                URL.revokeObjectURL(link.href)
                                document.body.removeChild(link)
                            } else {
                                navigator.msSaveBlob(pdfUrl, fileName)
                            }
                        }
                    } else {
                        downLoad(pdfUrl)
                    }
                } else {
                    message.warn(res.data.message);
                }
            })
    }

    const openCMBPdf = (url) => {
        url = url.replace("http","https");
        let win = window.open('about:blank');
        win.location.href = url;
        setTimeout(()=>{
            win.close();
        },500)
    }

    const base64ToBlob = (code) => {
        const raw = window.atob(code)
        const rawLength = raw.length
        const uInt8Array = new Uint8Array(rawLength)
        for (let i = 0; i < rawLength; i++) {
            uInt8Array[i] = raw.charCodeAt(i)
        }
        return new Blob([uInt8Array])
    }

    const downLoad = (url) => {
        //先声明一个异步请求对象
        var xmlHttpReg = new XMLHttpRequest(); //实例化一个xmlHttpReg
        //如果实例化成功,就调用open()方法,就开始准备向服务器发送请求
        if (xmlHttpReg != null) {
            xmlHttpReg.open("get", url, false);
            xmlHttpReg.send(null);

            if (xmlHttpReg.readyState == 4) {//4代表执行完成

                if (xmlHttpReg.status == 200) {//200代表执行成功
                    //将xmlHttpReg.responseText的值赋给ID为resText的元素
                    // document.getElementById("resText").innerHTML = xmlHttpReg.responseText;
                    window.open(url)

                } else {
                    message.warn("暂无电子回单，请下发次日15:00后再试")
                }
            }
        }
    }
    const columns = [
        {
            title: '公司名称',
            dataIndex: 'merchantName',
            render: text => <a>{text}</a>,
        },
        {
            title: '姓名',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '金额',
            dataIndex: 'paymentAmount',
            align: 'center',
        },
        {
            title: '附属信息',
            align: 'center',
            render: (text, record) => (
                <>
                    <p>证件：{record.idcardNumber}</p>
                    <p>账户：{record.receiveAccount}</p>
                    <p>卡属性：{"对私"}</p>
                    <p>备注：{record.remark}</p>
                    <p>银行渠道：{record.bankChannel}</p>
                </>
            ),
        },
        {
            title: '推送时间',
            dataIndex: 'updateTime',
            align: 'center',
        },
        {
            title: '状态',
            dataIndex: 'paymentStatus',
            align: 'center',
        },
        {
            title: '银行反馈消息',
            dataIndex: 'bankFeedbackMessage',
            width:200,
            align: 'center',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align: 'center',
        },
        {
            title: '操作',
            align: 'center',
            render: (text, record) => (
                <Button type="primary" onClick={() => downloadPdfByUrl(record.balanceDetailId)}>查看电子回单</Button>
            ),
        },
    ];
    return (
        <div>
            <Form
                initialValues={{remember: true}}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <Row style={{marginTop: 15}} justify="space-between">
                    <Col>
                        <Row gutter={10}>
                            <Col span={4}>
                                <Form.Item
                                    label="姓名"
                                    name="name"
                                >
                                    <Input placeholder="输入姓名" allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="身份证"
                                    name="idcardNumber"
                                >
                                    <Input placeholder="输入身份证" allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Select placeholder="请选择" allowClear onChange={setTimesFun}>
                                    <Option value="1">
                                        最近一周
                                    </Option>
                                    <Option value="2">
                                        最近一月
                                    </Option>
                                    <Option value="3">
                                        最近三月
                                    </Option>
                                </Select>
                            </Col>
                            <Col span={6}>

                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>

                            </Col>
                            <Col span={2}>
                                <Button type="primary" onClick={onFinishs}>查询</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: 15}} justify="space-between">
                    <Col span={8}>
                        <Row>
                            <Col span={11} offset={2}>
                                <Select placeholder="请选择" defaultValue={"4"} onChange={onChange2}>
                                    <Option value="4">
                                        全部发放状态
                                    </Option>
                                    <Option value="0">
                                        待发放
                                    </Option>
                                    <Option value="1">
                                        发放中
                                    </Option>
                                    <Option value="2">
                                        已发放
                                    </Option>
                                    <Option value="3">
                                        发放失败
                                    </Option>
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Select placeholder="请选择" allowClear onChange={setTimesFuns}
                                style={{marginRight: "20px", width: 120}}>
                            {selects()}
                        </Select>
                        <Button type="primary" icon={<FileTextOutlined/>} onClick={onExportFun}>导出</Button>
                    </Col>
                </Row>

            </Form>

            <Table size="small" columns={columns} dataSource={dataList.records} pagination={{
                total: dataList.total,
                onChange: pageOnChange,
                showTotal: (total) => `共 ${total} 条`,
                current: dataList.current
            }} style={{marginTop: "10px"}}/>
        </div>
    )
}
export default DistributionList
