import {axios} from "./axios"
// interface roomwxIService{
//     login(data):AxiosPromise,//新增请假

// }
const login =(data)=>{
    return  axios({
        method:"post",
        url:"/api/webLogin",
        data
    })
}
const getCode =(data)=>{
    return  axios({
        method:"post",
        url:"/api/kaptcha/alicode",
        data
    })
}
const codeLogins = (data)=>{
    return  axios({
        method:"post",
        url:"/api/codeLogin",
        data
    })
}
const getLogo = (data)=>{
    return  axios({
        method:"post",
        url:"/api/domain/getLogo",
        params:data
    })
}
export {login,getCode,codeLogins,getLogo}