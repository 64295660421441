import React, {useState} from 'react';
import {Input, message, Modal, Upload} from 'antd';
import {taskSettleFileUpload} from '../../../api/TaskSettlementList'

const {Dragger} = Upload;
const MultiImport = (props) => {
    let {isVisible, setIsVisible, getList, selectedRowKeys, setSelectedRowKeys} = props
    const [serviceConfirm, setserviceConfirm] = useState([])
    const [serviceSettle, setserviceSettle] = useState([])
    const onOk = () => {
        let businessId = []
        selectedRowKeys.forEach(item => {
            businessId.push(item.taskBalanceId)
        })
        let obj = new FormData()
        obj.append("businessId", businessId.toString())
        obj.append("file1", serviceConfirm[0])
        obj.append("file2", serviceSettle[0])
        taskSettleFileUpload(obj).then(res => {
            if (res.data.success) {
                message.success(res.data.message)
            } else {
                message.warn(res.data.message)
            }
            setIsVisible(false)
            getList()
            setSelectedRowKeys([])
        })
    }
    const onCancel = () => {
        setIsVisible(false)
    }
    const handleDocx = (e) => {
        setserviceSettle(e.target.files)
    }
    const handleExcel = (e) => {
        setserviceConfirm(e.target.files)
    }
    return (
        <Modal title="批量上传业务清单" visible={isVisible} onOk={onOk} onCancel={onCancel} getContainer={false}
               okText='确定' cancelText="取消"
        >
            服务确认函：<Input type="file" accept=".image/*,.pdf" onChange={handleDocx}></Input>
            服务费用结算清单：<Input type="file" accept=".xls" onChange={handleExcel}></Input>
        </Modal>
    )
}
export default MultiImport