import {axios} from "./axios"
import qs from 'qs'
const getDataList =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementFlow/pageList",
        data
    })
}
const getExport =(params)=>{
    return  axios({
        method:"get",
        url:"/api/taskSettlementFlow/exportInfoExcel",
        params
    })
}
const downloadPdf =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementFlow/download_pdf",
        data:qs.stringify(data)
    })
}
export {getDataList,getExport}
