import React, {useState, useEffect, useRef} from 'react';
import {Modal, Steps, Button, message, Checkbox, Input, Table} from 'antd'
import {subTaskSettleInfo} from '../../../api/TaskSettlement'
import {importsExcel} from "../../../until/excel";
import TaskSettlementConfirm from './TaskSettlementConfirm'
import './TaskSettlementImport.less'

const TaskSettlementImport = (props) => {
    const [upFlag, setUpflag] = useState(true)
    const [dataMsg, setDataMsg] = useState([])
    const [fileName, setFileName] = useState('')
    const [fileType, setFileType] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState(false)
    const [excelPersonNum, setExcelPersonNum] = useState(0)
    const [excelTotalAmount, setexcelTotalAmount] = useState(0)
    const [file, setFile] = useState([])
    const myRef = useRef()
    let {visible, onOk, onCancel, onFinish} = props
    const onConfirm = () => {
        setExcelPersonNum(dataMsg.length)
        let totalAmount = 0
        dataMsg.forEach(item => {
            if (item.paymentAmount) {
                totalAmount += Number(item.paymentAmount)
            }
        })
        setexcelTotalAmount(totalAmount)
        setIsConfirmVisible(true)
    }
    const handleOk = () => {
        onUpFan()
    }
    const handleCancel = () => {
        setIsConfirmVisible(false)
    }
    const onUpFan = () => {
        if (dataMsg.length) {
            let merchantServeId = window.localStorage.getItem("merchantServeId");
            let param = new FormData()
            param.append('merchantServeId', merchantServeId)
            param.append('totalAmount', excelTotalAmount)
            param.append('taskSettlementInfoStr', JSON.stringify(dataMsg))
            param.append('taskName', fileName)
            param.append("file", file[0])
            subTaskSettleInfo(param).then(res => {
                if (res.data.code === 200) {
                    message.success('导入成功');
                    onFinish()
                    onCancel()
                } else {
                    message.error(res.data.message);
                    setFileType(true)
                    setDataMsg(res.data.data.failList)
                }
            })
        } else {
            message.warn("请上传有效文件")
        }
        setIsConfirmVisible(false)
        setUpflag(true)
    }
    const handleExcel = (e) => {
        setFileType(false)
        if (e.target.value) {
            setFileName(e.target.files[0].name)
            importsExcel(e).then(
                function (data) {
                    if (data && data.length > 0) {
                        let arr = []
                        for (let i = 1; i < data.length; i++) {
                            if (data[i]["zipCode"] || data[i]["name"] || data[i]["receiveAccount"] || data[i]["paymentChannel"]
                                || data[i]["idcardType"]
                                || data[i]["idcardNumber"] || data[i]["phoneNumber"] || data[i]["paymentAmount"] || data[i]["bankChannel"]
                                || data[i]["accountBankName"]) {
                                data[i]["key"] = i

                                if (data[i]["zipCode"] != null) {
                                    data[i]["zipCode"] = data[i]["zipCode"].toString().trim();
                                }
                                if (data[i]["name"] != null) {
                                    data[i]["name"] = data[i]["name"].toString().trim();
                                }
                                if (data[i]["receiveAccount"] != null) {
                                    data[i]["receiveAccount"] = data[i]["receiveAccount"].toString().trim();
                                }
                                if (data[i]["paymentChannel"] != null) {
                                    data[i]["paymentChannel"] = data[i]["paymentChannel"].toString().trim();
                                }
                                if (data[i]["idcardType"] != null) {
                                    data[i]["idcardType"] = data[i]["idcardType"].toString().trim();
                                }
                                if (data[i]["idcardNumber"] != null) {
                                    data[i]["idcardNumber"] = data[i]["idcardNumber"].toString().trim();
                                }
                                if (data[i]["phoneNumber"] != null) {
                                    data[i]["phoneNumber"] = data[i]["phoneNumber"].toString().trim();
                                }
                                if (data[i]["paymentAmount"] != null) {
                                    data[i]["paymentAmount"] = data[i]["paymentAmount"].toString().trim();
                                }
                                if (data[i]["payRemark"] != null) {
                                    data[i]["payRemark"] = data[i]["payRemark"].toString().trim();
                                }
                                if (data[i]["bankChannel"] != null) {
                                    data[i]["bankChannel"] = data[i]["bankChannel"].toString().trim();
                                }
                                if (data[i]["accountBankName"] != null) {
                                    data[i]["accountBankName"] = data[i]["accountBankName"].toString().trim();
                                }

                                arr.push(data[i])
                            }
                        }
                        for (let i = 0; i < arr.length; i++) {
                            if (phoneNumberReg.test(arr[i]["phoneNumber"]) && idcardNumberReg.test(arr[i]["idcardNumber"])
                                && arr[i]["zipCode"] && arr[i]["name"] && arr[i]["receiveAccount"] && arr[i]["paymentChannel"]
                                && arr[i]["idcardType"] && arr[i]["idcardNumber"] && arr[i]["phoneNumber"] && arr[i]["paymentAmount"]
                            ) {
                                setUpflag(false)
                                setFile(e.target.files)
                            }
                        }
                        setDataMsg(arr);
                    }
                },
            )
        }
    }
    var phoneNumberReg = /^1\d{10}$/
    var idcardNumberReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            render: (text, record) =>
                (record.zipCode && record.name && record.receiveAccount && record.paymentChannel
                    && record.idcardType
                    && record.idcardNumber && record.phoneNumber && record.paymentAmount && phoneNumberReg.test(record.phoneNumber) && idcardNumberReg.test(record.idcardNumber)) ? (
                    <span>{text}</span>) : (<span style={{color: "red"}}>{text}</span>)
        },
        {
            title: '编码',
            dataIndex: 'zipCode'
        },
        {
            title: '姓名',
            dataIndex: 'name'
        },
        {
            title: '证件类型',
            dataIndex: 'idcardType',
            render: text =>
                <a>{text == 1 ? "身份证" : text == 2 ? "港澳台通行证" : text == 3 ? "护照" : text == 4 ? "军官证" : ""}</a>
        },
        {
            title: '身份证号码',
            dataIndex: 'idcardNumber'
        },
        {
            title: '手机号码',
            dataIndex: 'phoneNumber'
        },
        {
            title: '账号',
            dataIndex: 'receiveAccount'
        },
        {
            title: '银行名称',
            dataIndex: 'bankChannel'
        },
        {
            title: '开户行名称',
            dataIndex: 'accountBankName'
        },
        {
            title: '税后金额',
            dataIndex: 'paymentAmount'
        },
        {
            title: '备注',
            dataIndex: 'payRemark'
        },
        {
            title: '下发类型',
            dataIndex: 'paymentChannel'
        }
    ];
    const failColumns = [
        {
            title: '编码',
            dataIndex: 'zipCode'
        },
        {
            title: '姓名',
            dataIndex: 'name'
        },
        {
            title: '证件类型',
            dataIndex: 'idcardType',
            render: text =>
                <a>{text == 1 ? "身份证" : text == 2 ? "港澳台通行证" : text == 3 ? "护照" : text == 4 ? "军官证" : ""}</a>
        },
        {
            title: '身份证号码',
            dataIndex: 'idcardNumber'
        },
        {
            title: '手机号码',
            dataIndex: 'phoneNumber'
        },
        {
            title: '账号',
            dataIndex: 'receiveAccount'
        },
        {
            title: '银行名称',
            dataIndex: 'bankChannel'
        },
        {
            title: '开户行名称',
            dataIndex: 'accountBankName'
        },
        {
            title: '税后金额',
            dataIndex: 'paymentAmount'
        },
        {
            title: '备注',
            dataIndex: 'payRemark'
        },
        {
            title: '下发类型',
            dataIndex: 'paymentChannel'
        },
        {
            title: '失败原因',
            dataIndex: 'remark',
            render: remark => {
                return (
                    <span style={{color: 'red'}}>{remark}</span>
                )
            }
        }
    ];
    return (
        <Modal title="任务结算导入" visible={visible} onOk={onOk} onCancel={onCancel} width={1500} footer={null}>
            <div style={{color: '#e6a23c'}}>
                <div>提示：</div>
                <div>1、导入数据需要跟模板格式一样方可导入</div>
                <div>2、单个批次模板数据不能超过500条</div>
                <div>3、如果有未签约的数据，请先人才签约后再上传。</div>
            </div>
            <div style={{marginTop: '20px'}}>
                <div>
                    <Input ref={myRef} type="file" accept=".xls,.xlsx" onChange={handleExcel}
                           style={{width: 200, marginRight: 10}}></Input>
                    <Button type="primary" disabled={upFlag} onClick={onConfirm}>确定无误后，上传此表格数据</Button>
                </div>
                <Table columns={fileType ? failColumns : columns} dataSource={dataMsg}/>
            </div>
            {
                isConfirmVisible ? (
                    <TaskSettlementConfirm visible={isConfirmVisible} onOk={handleOk} onCancel={handleCancel}
                                           excelPersonNum={excelPersonNum} excelTotalAmount={excelTotalAmount}/>) : ""
            }
        </Modal>
    )
}
export default TaskSettlementImport
