import React, {useState, useEffect, useRef} from 'react';
import {Modal, Steps, Button, message, Checkbox, Input, Table, Tag, Row, Col, Select} from 'antd'
import {downloadPdf, settleDetailInfo, taskSettlementInfo} from '../../../api/TaskSettlement'
import './TaskSettlementImport.less'
import qs from 'qs'
import {
    ArrowLeftOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import {hideLoading, showLoading} from "../../../common/Xjkjload";

const TaskSettlementDetail = (props) => {
    const {Option} = Select
    let {taskBalanceId, setIsDetail,detailSynchronization,listState,onListState,row} = props
    const [data, setData] = useState([])
    const [personNum, setPersonNum] = useState("")
    const [totalAmount, setTotalAmount] = useState("")
    const [totalData, setTotalData] = useState([])
    const [paymentStatus, setPaymentStatus] = useState("")

    useEffect(() => {
      getList()
  }, [listState])

    //  打开同步小程序的弹框
    const onClickTongBu = ()=>{
      detailSynchronization()
    }

    const pageOnChange = (page, pageSize) => {
        getList(page, pageSize)
    }
    const setStatusFun = (value) => {
        setPaymentStatus(value)
    }

    const downloadPdfByUrl = async (id) => {
        showLoading()
        let param = {
            balanceDetailId: id
        }
        downloadPdf(param).then(
            res => {
                hideLoading()
                if (res.data.code === 200) {
                    let pdfUrl = res.data.data.pdfUrl;
                    // pdfUrl = "http://s3gw.cmbchina.com/s/L2xzMTItMTMtemlwLWNkYy4wMC8xNjAzOTE3OTA2NzA4MTc2ODk4LnppcD9BV1NBY2Nlc3NLZXlJZD1sczEyLjEzX3VzZXIwMSZFeHBpcmVzPTE2NzE2NzE1MjMmU2lnbmF0dXJlPXVPWTVvaWglMkZkQyUyRnZJckszN3dRUmdYZmhYRlklM0QmcmVzcG9uc2UtY29udGVudC1kaXNwb3NpdGlvbj1hdHRhY2htZW50JTNCZmlsZW5hbWUlM0QxNjAzOTE3OTA2NzA4MTc2ODk4LnppcA==/39b0MR0AwKH2xJT8QMkZ1gzntCs=/ls12.13_user01/0";
                    if (pdfUrl === '') {
                        message.warn("暂无电子回单，请次日15:00后重试");
                        return;
                    }
                    if (res.data.data.merchantType === 'huiji' || res.data.data.merchantType === 'lingtu') {
                        window.open(pdfUrl)
                    } else if (res.data.data.merchantType === 'cmb') {
                        if (res.data.data.payType === 'replace') {
                            // window.open(pdfUrl);
                            // const w = window.open(pdfUrl);
                            // w.location.href = pdfUrl
                            openCMBPdf(pdfUrl);
                        } else {
                            const fileName = res.data.data.name + ' 电子回单' + res.data.data.balanceDetailId + '.pdf'        //附件名包含附件类型
                            if ('download' in document.createElement('a')) {
                                console.log('下载成功')
                                let data = base64ToBlob(pdfUrl)
                                const link = document.createElement('a')
                                link.download = fileName
                                link.style.display = 'none'
                                link.href = URL.createObjectURL(data)
                                document.body.appendChild(link)
                                link.click()
                                URL.revokeObjectURL(link.href)
                                document.body.removeChild(link)
                            } else {
                                navigator.msSaveBlob(pdfUrl, fileName)
                            }
                        }
                    } else {
                        downLoad(pdfUrl)
                    }
                } else {
                    message.warn(res.data.message);
                }
            })
    }

    const openCMBPdf = (url) => {
        url = url.replace("http","https");
        let win = window.open('about:blank');
        win.location.href = url;
        setTimeout(()=>{
            win.close();
        },500)
    }

    const base64ToBlob = (code) => {
        const raw = window.atob(code)
        const rawLength = raw.length
        const uInt8Array = new Uint8Array(rawLength)
        for (let i = 0; i < rawLength; i++) {
            uInt8Array[i] = raw.charCodeAt(i)
        }
        return new Blob([uInt8Array])
    }

    const downLoad = (url) => {
        //先声明一个异步请求对象
        var xmlHttpReg = new XMLHttpRequest(); //实例化一个xmlHttpReg
        //如果实例化成功,就调用open()方法,就开始准备向服务器发送请求
        if (xmlHttpReg != null) {
            xmlHttpReg.open("get", url, false);
            xmlHttpReg.send(null);

            if (xmlHttpReg.readyState == 4) {//4代表执行完成

                if (xmlHttpReg.status == 200) {//200代表执行成功
                    //将xmlHttpReg.responseText的值赋给ID为resText的元素
                    // document.getElementById("resText").innerHTML = xmlHttpReg.responseText;
                    window.open(url)

                } else {
                    message.warn("暂无电子回单，请下发次日15:00后再试")
                }
            }
        }
    }
    const turnBack = () => {
        setIsDetail(false)
        onListState(false)
    }
    const onExportFun = () => {
        let values = {}
        values.taskBalanceId = taskBalanceId;
        window.open(`/api/taskSettlementFlow/exportFlowInfoExcel?${qs.stringify(values)}`, {
            method: 'GET',
            Authorization: window.localStorage.getItem("token")
        });
    }
    const onFinishs = () => {
        getList()
    }

    const getList = (page = 1, limit = 10) => {
        let param = {
            page: page,
            limit: limit,
            taskBalanceId: taskBalanceId,
            paymentStatus: paymentStatus
        }
        settleDetailInfo(param).then(res => {
            setPersonNum(res.data.data.personNum)
            setTotalAmount(res.data.data.totalAmount)
            if (res.data.data.dataInfo.records) {
                for (let i = 0; i < res.data.data.dataInfo.records; i++) {
                    res.data.data.dataInfo.records[i]["key"] = i + 1
                }
            }

            if (res.data.data.dataInfo.records.length>0){
              res.data.data.dataInfo.records.forEach(item=>{
                let str =    parseInt(item.bankFeedbackTime.charAt(0))>-1
                if(item.bankFeedbackTime&&!str){
                  var dateStr=item.bankFeedbackTime.split(" ");
                  var strGMT = dateStr[0]+" "+dateStr[1]+" "+dateStr[2]+" "+dateStr[5]+" "+dateStr[3]+" GMT+0800";
                  var dateObj = new Date(Date.parse(strGMT));
                  // const dateStr = item.bankFeedbackTime;
                  // const dateObj = new Date(dateStr);
                  const year = dateObj.getFullYear();
                  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
                  const day = ("0" + dateObj.getDate()).slice(-2);
                  const hours = ("0" + dateObj.getHours()).slice(-2);
                  const minutes = ("0" + dateObj.getMinutes()).slice(-2);
                  const seconds = ("0" + dateObj.getSeconds()).slice(-2);
                  item.bankFeedbackTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                }

              })
            }
            let topData = {}
            let topDataArray = []
            topData.personNum = res.data.data.personNum
            topData.failAmount = res.data.data.failAmount
            topData.failNum = res.data.data.failNum
            topData.succcessAmount = res.data.data.succcessAmount
            topData.succcessNum = res.data.data.succcessNum
            topData.totalAmount = res.data.data.totalAmount
            topData.key = 1
            topDataArray.push(topData)
            setTotalData(topDataArray)
            // console.log("<<<<<<<",res.data.data);
            setData(res.data.data.dataInfo)
        }).catch(error => {
            console.log(error.message)
        })
    }
    useEffect(() => {
        getList()
    }, [paymentStatus])
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            align: 'center',
            key: 'name'
        },
        {
            title: '收款账号',
            dataIndex: 'receiveAccount',
            align: 'center',
            key: 'receiveAccount'
        },
        {
            title: '附属信息',
            render: (data, row) => {
                return (
                    <div>
                        <div>证件号码：{row.idcardNumber}</div>
                        <div>手机号码：{row.phoneNumber}</div>
                        <div>编码：{row.zipCode}</div>
                        <div>下发时间：{row.bankFeedbackTime}</div>
                    </div>
                )
            },
            align: 'center',
            key: 'idcardNumber'
        },
        {
            title: '下发金额',
            dataIndex: 'paymentAmount',
            align: 'center',
            key: 'paymentAmount'
        },
        {
            title: '备注',
            dataIndex: 'payRemark',
            align: 'center',
            key: 'payRemark'
        },
        {
            title: '银行回馈码',
            dataIndex: 'bankFeedbackCode',
            align: 'center',
            key: 'bankFeedbackCode',
            render: (data, row) => {
                return row.paymentStatus == "已发放" ?
                    (<Tag color="green">{row.bankFeedbackCode}</Tag>) :
                    (<Tag color="red">{row.bankFeedbackCode}</Tag>)
            }
        },
        {
            title: '银行回馈消息',
            dataIndex: 'bankFeedbackMessage',
            align: 'center',
            key: 'bankFeedbackMessage',
            render: (data, row) => {
                return row.paymentStatus == "已发放" ?
                    (<div style={{color: "#87d068"}}>{row.bankFeedbackMessage}</div>) :
                    (<div style={{color: "#f12222"}}>{row.bankFeedbackMessage}</div>)
            }
        },
        {
            title: '发放状态',
            dataIndex: 'paymentStatus',
            align: 'center',
            key: 'paymentStatus',
            render: paymentStatus => {
                return paymentStatus == "已发放" ?
                    <div style={{color: "#87d068"}}>{paymentStatus}</div> :
                    (<div style={{color: "#f12222"}}>{paymentStatus}</div>)
            }
        },
        {
          title: '同步状态',
          dataIndex: 'synchronizeOutBizNo',
          align: 'center',
          key: 'synchronizeOutBizNo',
          render: paymentStatus => {
              return paymentStatus  ?
                  <div style={{color: "#87d068"}}>已同步</div> :
                  (<div style={{color: "#f12222"}}>未同步</div>)
          }
      },
        {
            title: '电子回单',
            align: 'center',
            render: (data, row) => {
                return (
                    <Button style={{background: '#f0f9eb', color: '#67c23a', borderColor: '#c2e7b0'}}
                            onClick={() => downloadPdfByUrl(row.balanceDetailId)}>下载电子回单</Button>
                )
            }
        }
    ];
    const totalColumns = [
        {
            title: '结算笔数',
            dataIndex: 'personNum',
            align: 'center',
            key: 'personNum'
        },
        {
            title: '结算金额',
            dataIndex: 'totalAmount',
            align: 'center',
            key: 'totalAmount'
        },
        {
            title: '成功笔数',
            dataIndex: 'succcessNum',
            align: 'center',
            key: 'succcessNum'
        },
        {
            title: '成功金额',
            dataIndex: 'succcessAmount',
            align: 'center',
            key: 'succcessAmount'
        },
        {
            title: '失败笔数',
            dataIndex: 'failNum',
            align: 'center',
            key: 'failNum'
        },
        {
            title: '失败金额',
            dataIndex: 'failAmount',
            align: 'center',
            key: 'failAmount'
        },
    ]
    return (
        <div>
            <Button icon={<ArrowLeftOutlined/>} onClick={turnBack}>返回</Button>
            <div style={{
                height: '50px',
                background: '#1890ff',
                color: "#fff",
                fontSize: '20px',
                textAlign: 'center',
                lineHeight: "50px",
                marginTop: '20px'
            }}>发放明细
            </div>
            <Table columns={totalColumns} dataSource={totalData} pagination={false}/>
            <div style={{height: '50px', marginLeft: '20px', marginTop: '20px', fontSize: '16px'}}>
                <Row>
                    <Col span={4}>
                        <span>总笔数：</span>
                        <span style={{color: 'red'}}>{personNum}</span>
                    </Col>
                    <Col span={4}>
                        <span>总金额：</span>
                        <span style={{color: 'red'}}>{totalAmount}</span>
                    </Col>

                    <Col span={2} offset={8}>
                        <Select placeholder="发放状态" allowClear onChange={setStatusFun}>
                            <Option value="2">
                                发放成功
                            </Option>
                            <Option value="3">
                                发放失败
                            </Option>
                        </Select>
                    </Col>

                    <Col span={1} offset={0}>
                        <Button type="primary" onClick={onFinishs}>查询</Button>
                    </Col>

                    <Col span={1} offset={0}>
                    {row.paymentStatus=='2'||row.paymentStatus=='4'?<Button style={{marginLeft: '20px'}} type="primary" onClick={onClickTongBu}>同步</Button>:''}

                    </Col>

                    <Col span={1} offset={0}>
                        <Button style={{marginLeft: '40px'}} type="primary" icon={<FileTextOutlined/>} onClick={onExportFun}>导出</Button>
                    </Col>
                </Row>
            </div>
            <Table columns={columns} dataSource={data.records} pagination={{
                total: data.total,
                onChange: pageOnChange,
                showTotal: (total) => `共 ${total} 条`,
                current: data.current
            }}/>
        </div>
    )
}
export default TaskSettlementDetail
