import {axios} from "./axios"
import qs from 'qs'
const taskSettlementListInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/pageList",
        data:qs.stringify(data)
    })
}
// 批量上传任务结算清单文件
const taskSettleFileUpload =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettleFileUpload",
        data:data
    })
}
const getListS =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/list",
        data:qs.stringify(data)
    })
}
const getSh =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/editAuditStatus",
        data:qs.stringify(data)
    })
}
export {taskSettlementListInfo, taskSettleFileUpload,getListS,getSh}