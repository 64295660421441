import React, {createRef, useEffect, useRef, useState} from 'react'
import ReactSimpleVerify from 'react-simple-verify'
import 'react-simple-verify/dist/react-simple-verify.css'
import style from './Login.module.less'
import './Login.less'
import {useHistory} from 'react-router-dom'
import {Button, Form, Input, message} from 'antd';
import qs from 'qs'
import {codeLogins, getCode, getLogo, login} from "../../api/Login"
import {hideLoading, showLoading} from "../../common/Xjkjload"


const Login = () => {
    const [flag, setFlag] = useState(true)
    const [isImg, setIsImg] = useState(true)
    const [time, setTime] = useState(60);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnContent, setBtnContent] = useState('获取验证码');
    const [imgUrl, setImgUrl] = useState("/api/kaptcha/picCode")
    const [logoName, setLogoName] = useState("薪津科技")
    const [verifyWidth, setWidth] = useState(1280)
    const [ftSize, setFtSize] = useState("40px")
    const [logoImg, setLogoImg] = useState("https://download.027xjkj.com/download/1470278640989114369.png")
    const history = useHistory()
    const [form] = Form.useForm();
    const verifyRef = createRef()

    const windowWidth = window.screen.width;

    const [componentSize, setComponentSize] = useState('default');

    const onFormLayoutChange = ({size}) => {
        setComponentSize(size);
    };

    let timeChange = useRef();
    let state = false
    const onFinish = async (values) => {
        try {
            let res
            let newValues = qs.stringify(values)
            if (flag) {
                //  滑块状态为 false  则  return
                if (!state) {
                    message.warn("请完成滑动验证")
                    return
                }
                verifyRef.current.reset()
                state = false
                showLoading()
                res = await login(newValues)
                hideLoading()

            } else {
                showLoading()
                res = await codeLogins(newValues)
                hideLoading()
            }

            if (res.data.success) {
                message.success(res.data.message);
                window.localStorage.setItem("token", res.data.data);
                history.push('/Home')
            } else {
                message.warning(res.data.message);
            }
        } catch (e) {
            hideLoading()
            message.error(e.message);
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {


    }, [logoName])

    //获取手机短信验证码
    const getCodeFun = async () => {
        let values = await form.validateFields();
        let {phone} = values
        let obj = {phone}
        let newValues = qs.stringify(obj)
        showLoading()
        let res = await getCode(newValues)
        hideLoading()
    }
    useEffect(() => {
        const {hostname} = window.location;
        var logoImgs = document.getElementById("logo_img")
        if (hostname == "localhost") {
            setLogoName("")
            setLogoImg("https://download.027xjkj.com/download/1610808328982630401.png")
            // setLogoName("薪津科技")
            // setLogoImg("https://download.027xjkj.com/download/1470278640989114369.png")
            logoImgs.href = "https://download.027xjkj.com/download/1610808328982630401.png"
        } else if (hostname.startsWith("192")) {
            setLogoName("薪津科技")
            setLogoImg("https://download.027xjkj.com/download/1610808328982630401.png")
            logoImgs.href = "https://download.027xjkj.com/download/1610808328982630401.png"
        } else {
            let a = hostname.indexOf(".")
            let name = hostname.slice(0, a)
            if (name == "www" || name == "salary") {
                setLogoName("")
                setLogoImg("https://download.027xjkj.com/download/1610808328982630401.png")
                logoImgs.href = "https://download.027xjkj.com/download/1470278640989114369.png"
                document.title = "薪津科技"
            } else {
                let obj = {domainName: name}
                getLogo(obj).then(res => {
                    if (res.data.success) {
                        setLogoName(res.data.data.domainTitle)
                        setLogoImg(res.data.data.domainLogo)
                        logoImgs.href = res.data.data.domainLogo
                        document.title = res.data.data.domainTitle
                    }
                })
            }
        }
        if (window.localStorage.getItem("token")) {
            history.push('/Home')
        }

    }, [])
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setBtnContent(`${time}s后重发`);
        } else {
            clearInterval(timeChange.current);
            setBtnContent("获取验证码")
            setBtnDisabled(false);
            setTime(60);
        }
    }, [time]);
    const getPhoneCaptcha = () => {
        getCodeFun().then(() => {
            // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
            timeChange.current = setInterval(() => setTime(t => --t), 1000);
            setBtnDisabled(true);
        })
    };

    const handleImageLoaded = () => {
        setIsImg(true)
    }

    const handleImageErrored = () => {
        setIsImg(false)
    }

    //  修改滑块状态
    const success = () => {
        state = !state
    }

    const getVerifyWidth = () => {
        let width = windowWidth * 0.2024;
        console.log("width : " + width)
        return width;
    }

    return (
        <div className={style.Login_body}>

            <div className={style.Login_main}>
                <div className={style.Login_title}>
                    <div className={style.Login_title_one}>欢迎访问</div>
                    <div className={style.Login_title_two}>灵活用工一站式服务平台</div>
                </div>
                {/* 登录 */}
                <div className={style.Login_put}>
                    <div className={style.Login_logo}>
                        <img src={logoImg}
                             onLoad={handleImageLoaded.bind(this)}
                             onError={handleImageErrored.bind(this)}
                             className={isImg ? style.Login_logo_img : style.hideImage}/>
                        <div className={style.Login_logo_h2}>{logoName}</div>
                    </div>
                    <Form
                        name="basic"
                        labelCol={{span: 0}}
                        wrapperCol={{span: 0}}
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                        className={style.Login_form}
                        // layout="horizontal"
                        // initialValues={{
                        //     size: componentSize,
                        // }}
                        // onValuesChange={onFormLayoutChange}
                        // size={"small"}
                    >
                        {
                            flag ? (
                                <div>
                                    <Form.Item
                                        name="account"
                                        rules={[{required: true, message: '请输入账户名!'}]}
                                        wrapperCol={{offset: 3, span: 18}}
                                    >
                                        {/* prefix={<UserOutlined />} */}
                                        <Input placeholder="请输入账户名" prefix={<div className='username'/>}
                                               size="large"/>
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        rules={[{required: true, message: '请输入密码!'}]}
                                        wrapperCol={{offset: 3, span: 18}}

                                    >
                                        <Input.Password placeholder="请输入密码" prefix={<div className='paw'/>}
                                                        size="large"/>
                                        {/* <Input.Password placeholder="请输入密码"   addonBefore={<div className='paw' style={{ width: 40, height: 70, lineHeight: '70px'}}></div>} size="large" /> */}
                                    </Form.Item>


                                    {/* 滑块验证 */}
                                    <Form.Item
                                        // validateStatus={"error"}
                                        // hasFeedback
                                        // help={'请拖动滑块解锁!'}

                                        wrapperCol={{offset: 3, span: 18}}>
                                        <ReactSimpleVerify ref={verifyRef} width={getVerifyWidth()}
                                                           height='3.5vw' success={success} tips="滑动滑块完成校验"/>
                                    </Form.Item>
                                </div>
                            ) : (<>
                                <Form.Item
                                    name="phone"
                                    rules={[{
                                        required: true,
                                        message: '请输入正确的手机号!',
                                        pattern: /^1[34578]\d{9}$/,
                                    }]}
                                    wrapperCol={{offset: 3, span: 18}}
                                >

                                    <Input placeholder="请输入手机号" prefix={<div className='ipbefore'/>}
                                           size="large"/>
                                </Form.Item>

                                <Form.Item
                                    name="kaptcha"
                                    // rules={[{ required: true, message: '请输入短信验证码!' }]}
                                    wrapperCol={{offset: 3, span: 18}}
                                >
                                    <Input size="large" prefix={<div className='vercode'/>} placeholder="请输入验证码"
                                           suffix={
                                               <Button type="link" size="small" onClick={getPhoneCaptcha}>
                                                   {btnContent}
                                               </Button>}
                                    />
                                </Form.Item></>)
                        }

                        <Form.Item wrapperCol={{offset: 3, span: 18}} className={style.btn - login}>
                            <Button type="primary" htmlType="submit" block={true} size="large">
                                <span>登</span>
                                <span>录</span>
                            </Button>
                        </Form.Item>

                    </Form>

                    <Form.Item

                        wrapperCol={{offset: 3, span: 12}}>

                        <Button
                            className={style.red}
                            type="link" size="small" onClick={() => {
                            setFlag(!flag);
                            form.resetFields();
                        }}>
                            {flag ? '短信验证码登录' : '用户名密码登录'}
                        </Button>
                    </Form.Item>
                </div>
            </div>


            <div className={style.Home_title_footer}>
                <div className={style.Home_title_footer_div}>
                    <a target="_blank" className={style.Home_title_footer_div_a}
                       href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004646"><img
                        className={style.Home_title_footer_div_img}/>
                        <p className={style.Home_title_footer_div_p}>鄂公网安备 42011102004646号</p>
                        <p className={style.Home_title_footer_div_p}>Copyright 薪津科技 All Rights Reserved</p>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Login;
