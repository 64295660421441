import React, {useState, useEffect} from 'react';
import style from './Onlinelnvoicing.module.less'
import {Row, Col, DatePicker, Space, Button, Radio, Card, Input, message, Select, Table, Tag, Tabs} from 'antd';
import {getJr, getDataList} from "../../api/OnlineInvoicing"
import OnlinePage from "./components/OnlinePage"
import OnlineDetails from "./components/OnlineDetails"
import PhoneValidKP from ".././views/components/PhoneValidKP"
import KaiPiao from "./components/KaiPiao"
import moment from 'moment';
import {
    PaperClipOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import qs from 'qs'
import {showLoading, hideLoading} from "../../common/Xjkjload"

const OnlineInvoicing = () => {
    const [dataJr, setDataJr] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isKaiPiaoModalVisible, setIsKaiPiaoModalVisible] = useState(false);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
    const [phone, setPhone] = useState("")
    const [values, setValues] = useState({})
    const [time, setTime] = useState([undefined, undefined])
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const dateFormat = 'YYYY-MM-DD' || undefined;
    const {Option} = Select
    const [dataList, setDataList] = useState([])
    const {RangePicker} = DatePicker;
    const {TabPane} = Tabs;
    const [keys, setKeys] = useState("-1")
    const [records, setRecords] = useState({})
    let merchantServeId = window.localStorage.getItem("merchantServeId");
    useEffect(() => {
        let obj = {merchantServeId}
        let newValues = qs.stringify(obj)
        getJr(newValues).then(res => {
            setDataJr(res.data.data)
        })
        obj.page = 1;
        obj.limit = 10;
        let newValue = qs.stringify(obj)
        showLoading()
        getDataList(newValue).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        }).catch(() => {
            hideLoading()
        })
    }, [])
    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
    }
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const handleOk = () => {
        setIsModalVisible(false);
        setIsPhoneModalVisible(true)
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOkKp = () => {
        setIsKaiPiaoModalVisible(false);
    };
    const handleCancelKp = () => {
        setIsKaiPiaoModalVisible(false);
    };
    const showModalKp = (records) => {
        if (records.flag) {
            delete records.flag
        }
        setRecords(records)
        setIsKaiPiaoModalVisible(true);
    };
    const showModalKps = (records) => {
        if (records.invoiceStatus == "复核中") {
            records.flag = true
        }
        setRecords(records)
        setIsKaiPiaoModalVisible(true);
    };
    const handleOks = () => {
        setIsDetailsModalVisible(false);
    };
    const handleCancels = () => {
        setIsDetailsModalVisible(false);
    };
    const showModals = (records) => {
        if (records.flag) {
            delete records.flag
        }
        setRecords(records)
        setIsDetailsModalVisible(true);
    };
    const onFinishss = async (page = 1, limit = 10) => {
        let values = {merchantServeId}
        values.startTime = time[0];
        values.endTime = time[1];
        values.page = page;
        if (keys != "-1") {
            values.invoiceStatus = keys
        }
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        }).catch(() => {
            hideLoading()
        })
    }
    const onFinish = async (page = 1, limit = 10, type = "-1") => {
        let values = {merchantServeId}
        values.startTime = time[0];
        values.endTime = time[1];
        values.page = page;
        if (type != "-1") {
            values.invoiceStatus = type
        }
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        }).catch(() => {
            hideLoading()
        })
    }
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    const callback = (key) => {
        setKeys(key)
        onFinish(1, 10, key)
    }
    const handlePhoneOk = () => {
        setIsPhoneModalVisible(false)
    }
    const columns = [
        {
            title: '编号',
            dataIndex: 'merchantInvoiceId',
        },
        {
            title: '申请开票时间',
            dataIndex: 'applyTime',
        },
        {
            title: '申请开票金额',
            dataIndex: 'invoiceAmount',
        },
        {
            title: '开票类目',
            dataIndex: 'invoiceCategory',
        },
        {
            title: '开票状态',
            dataIndex: 'invoiceStatus',
        },
        {
            title: '驳回原因',
            dataIndex: 'rejectReason',
        },
        {
            title: '操作',
            render: (text, record) =>
                <div>
                    <Button style={{marginRight: "10px"}} onClick={() => showModals(record)}>申请详情</Button>
                    {
                        record.invoiceStatus
                        == "审核通过" || record.invoiceStatus
                        == "发票寄出" ? (<Button style={{marginRight: "10px"}}
                                             onClick={() => showModalKp(record)}>开票详情</Button>) : ""
                    }
                </div>
            ,
        },
    ];
    const setTimesFun = (value) => {
        if (value == 2) {
            let startDate = moment().startOf('month').format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 1) {
            let startDate = moment().day(moment().day() - 6).format('YYYY-MM-DD') // 当前时间往前推一周的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 3) {
            let startDate = moment().subtract(1, 'quarters').format('YYYY-MM-DD ') // 当前时间往前推三个月的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const onFinishs = () => {
        let obj = {merchantServeId}
        let newValues = qs.stringify(obj)
        getJr(newValues).then(res => {
            setDataJr(res.data.data)
        })
        onFinishss()
    }
    const handlePhoneCancel = () => {
        setIsPhoneModalVisible(false)
    }
    return (
        <div className={style.OnlineInvo}>
            <div className={style.OnlineInvo_header}>
                <Row gutter={16}>
                    <Col span={8}> <Card title="可开票金额" bordered={false} style={{background: "#f7f8fa"}}>
                        {dataJr.residueMoney ? dataJr.residueMoney : 0}
                    </Card></Col>
                    <Col span={8}> <Card title="开票中金额" bordered={false} style={{background: "#f7f8fa"}}>
                        {dataJr.inInvoiceMoney ? dataJr.inInvoiceMoney : 0}
                    </Card></Col>
                    <Col span={8}> <Card title="已开票金额" bordered={false} style={{background: "#f7f8fa"}}>
                        {dataJr.invoicedMoney ? dataJr.invoicedMoney : 0}
                    </Card></Col>
                    {/* <Col span={6}> <Card title="退款金额" bordered={false} style={{ background: "#f7f8fa" }}>
                        4
                    </Card></Col> */}
                </Row>
            </div>
            <div>
                <Row style={{marginTop: 15}} justify="space-between">
                    <Col>
                        <Row gutter={10}>
                            <Col>
                                <Button type="primary" onClick={showModal}
                                        disabled={ptCheckerFlag == 1 ? true : false}>申请开票</Button>
                            </Col>
                            <Col>

                                <Select placeholder="请选择" allowClear onChange={setTimesFun}>
                                    <Option value="1">
                                        最近一周
                                    </Option>
                                    <Option value="2">
                                        最近一月
                                    </Option>
                                    <Option value="3">
                                        最近三月
                                    </Option>
                                </Select>

                            </Col>
                            <Col>
                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>

                            </Col>
                            <Col>
                                <Button type="primary" onClick={onFinishs}>查询</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: "10px"}}>
                <Tabs onChange={callback} type="card">
                    <TabPane tab="全部" key="-1">
                    </TabPane>
                    {/* <TabPane tab="待提交" key="0">
                    </TabPane> */}
                    <TabPane tab="审核中" key="1">
                    </TabPane>
                    <TabPane tab="审核驳回" key="2">
                    </TabPane>
                    <TabPane tab="审核通过" key="3">
                    </TabPane>
                    <TabPane tab="发票寄出" key="4">
                    </TabPane>
                </Tabs>
                <Table columns={columns} dataSource={dataList.records} pagination={{
                    total: dataList.total,
                    onChange: pageOnChange,
                    showTotal: (total) => `共 ${total} 条`,
                    current: dataList.current
                }}></Table>
            </div>
            {
                isModalVisible ? (
                    <OnlinePage visible={isModalVisible} onOk={handleOk} setPhone={setPhone} setValues={setValues}
                                onCancel={handleCancel} dataJr={dataJr}/>) : ""
            }
            {
                isDetailsModalVisible ? (
                    <OnlineDetails visible={isDetailsModalVisible} onOk={handleOks} onCancel={handleCancels}
                                   records={records}/>) : ""
            }
            {
                isKaiPiaoModalVisible ? (
                    <KaiPiao visible={isKaiPiaoModalVisible} onOk={handleOkKp} onCancel={handleCancelKp}
                             records={records}/>) : ""
            }
            {
                isPhoneModalVisible ? (
                    <PhoneValidKP visible={isPhoneModalVisible} values={values} onFinishs={onFinishs} phone={phone}
                                  onOk={handlePhoneOk}
                                  onCancels={handlePhoneCancel}
                    />) : ""
            }
        </div>
    )
}
export default OnlineInvoicing
