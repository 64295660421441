import {axios} from "./axios"

const getDataList=(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantServeFlow/pageList",
        data
    })
}

export {getDataList}