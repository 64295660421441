import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Descriptions, Form, Input, message, Row, Select, Space, Table} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {getRenCaiMsg} from '../../api/TaskRelease';
import ImportPages from './components/ImportPages'
import moment from 'moment';
import {hideLoading, showLoading} from "../../common/Xjkjload"
import qs from 'qs'
import {FileTextOutlined, PaperClipOutlined} from '@ant-design/icons';

const TaskRelease = () => {
    const {Option} = Select
    const {RangePicker} = DatePicker;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showTotal, setShowTotal] = useState(null)
    const [data, setData] = useState({})
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const [time, setTime] = useState([undefined, undefined])
    const [form] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD' || undefined;
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        onFinishs()
        setIsModalVisible(false);
    };
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const onFinish = async (page = 1, limit = 10) => {
        let values = await form.validateFields();
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = window.localStorage.getItem("merchantServeId")
        values.page = page;
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getRenCaiMsg(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setData(res.data.data)
            setShowTotal(res.data.data.total)
        })
    };

    const onExportFun = async () => {
        let values = await form.validateFields();
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = window.localStorage.getItem("merchantServeId")
        window.open(`/api/signInfo/exportInfoExcel?${qs.stringify(values)}`, {
            method: 'GET',
            Authorization: window.localStorage.getItem("token")
        });
    }
    useEffect(() => {
        let merchantServeId = window.localStorage.getItem("merchantServeId");
        let obj = {page: 1, limit: 10, merchantServeId}
        let newValues = qs.stringify(obj)
        showLoading()
        getRenCaiMsg(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setData(res.data.data)
            setShowTotal(res.data.data.total)
        })
    }, [])
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
        console.log(page, pageSize)
    }
    const getXlsx = () => {
        const w = window.open('_black')
        let url = 'https://www.027xjkj.com/人才导入模板.xlsx'
        w.location.href = url
    }
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    const onFinishs = () => {
        onFinish()
    }
    const setTimesFun = (value) => {
        if (value == 2) {
            let startDate = moment().startOf('month').format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 1) {
            let startDate = moment().day(moment().day() - 6).format('YYYY-MM-DD') // 当前时间往前推一周的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 3) {
            let startDate = moment().subtract(1, 'quarters').format('YYYY-MM-DD ') // 当前时间往前推三个月的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const ajax = (data) => {
        let obj = {fileId: data}
        //先声明一个异步请求对象
        var xmlHttpReg = new XMLHttpRequest(); //实例化一个xmlHttpReg
        //如果实例化成功,就调用open()方法,就开始准备向服务器发送请求
        if (xmlHttpReg != null) {
            xmlHttpReg.open("get", `api/download?${qs.stringify(obj)}`, false);
            xmlHttpReg.setRequestHeader('Authorization', `${window.localStorage.getItem("token")}`);
            xmlHttpReg.send(null);

            if (xmlHttpReg.readyState == 4) {//4代表执行完成

                if (xmlHttpReg.status == 200) {//200代表执行成功
                    //将xmlHttpReg.responseText的值赋给ID为resText的元素
                    // document.getElementById("resText").innerHTML = xmlHttpReg.responseText;
                    window.open(` api/download?${qs.stringify(obj)}`)

                } else {
                    message.warn("暂时没有协议文件")
                }
            }
        }
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'signId',
            render: text => <a>{text}</a>,
            width: 100
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: 100
        },
        {
            title: '证件号码',
            dataIndex: 'idcardNumber',
            width: 150
        },
        {
            title: '服务类型',
            dataIndex: 'serveType',
            width: 200,
            ellipsis: true
        },
        {
            title: '签约状态',
            dataIndex: 'signStatus',
            width: 100,
            render: (text, record) => {
                if (record.signStatus === '0') {
                    return (
                        <span style={{color: '#000000'}}>待签约</span>
                    )
                } else if (record.signStatus === '1') {
                    return (
                        <span style={{color: '#1890ff'}}>已签约</span>
                    )
                } else if (record.signStatus === '2') {
                    return (
                        <span style={{color: '#000000'}}>签约中</span>
                    )
                } else {
                    return (
                        <span style={{
                            color: '#f56c6c', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal'
                        }}>{record.remark}</span>
                    )
                }
            },
            ellipsis: true
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            width: 100,
        },
        {
            title: '操作',
            render: (text, record) => {
                if (record.signStatus === '1') {
                    return (<Button type="primary" onClick={() => ajax(record.pdfId)}>查看协议</Button>)
                }
            },
            width: 100
        },
        // {
        //     title: '操作',
        //     render: (text, record) => (
        //         <Button type="primary">查看协议</Button>
        //     ),
        // },
    ];
    return (
        <div>
            <Row justify="space-between">
                <Col><Button type="primary" onClick={showModal} icon={<PaperClipOutlined/>}
                             disabled={ptCheckerFlag == 1 ? true : false}>人才Excel导入</Button>
                    <Button type="link" onClick={getXlsx}> 人才导入模板.xlsx </Button></Col>
                <Col span={3}> <Descriptions>
                    <Descriptions.Item label="已签约人数" contentStyle={{color: "red"}}>{showTotal}</Descriptions.Item>
                </Descriptions></Col>
            </Row>

            <Form
                initialValues={{remember: true}}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <Row style={{marginTop: 15}} justify="space-between">
                    <Col>
                        <Row gutter={10}>
                            <Col>
                                <Form.Item
                                    label="姓名"
                                    name="name"
                                >
                                    <Input placeholder="输入姓名"/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Select placeholder="请选择" allowClear onChange={setTimesFun}>
                                    <Option value="1">
                                        最近一周
                                    </Option>
                                    <Option value="2">
                                        最近一月
                                    </Option>
                                    <Option value="3">
                                        最近三月
                                    </Option>
                                </Select>
                            </Col>
                            <Col>

                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={onFinishs}>查询</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row gutter={10}>
                            <Col>
                                <Button type="primary" icon={<FileTextOutlined/>} onClick={onExportFun}>导出</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Form>
            <Table columns={columns} dataSource={data.records} pagination={{
                total: data.total,
                onChange: pageOnChange,
                showTotal: (total) => `共 ${total} 条`,
                current: data.current
            }}/>
            {
                isModalVisible ? (<ImportPages visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}/>) : ""
            }

        </div>
    )
}
export default TaskRelease
