import React, { useState, useEffect } from 'react';
import { Descriptions } from 'antd';
import style from './AccountInformation.module.less'
import {getMerchantServeInfo} from '../../api/accountInformation.js'
const AccountInformation = () => {
    const [data, updata] = useState ({
        accountBankName: '',
        serviceRate: '',
        companyAccount: '',
        paymentAccountName: '',
        accountBank: '',
        paymentAccount: '',
        serveAccountBank: '',
        accountBalance: ''
    })
    useEffect(()=>{
        let data = window.localStorage.getItem('merchantServeId')
        let param = {
            merchantServeId: data
        }
        getMerchantServeInfo(param).then(res=>{
            updata(res.data.data)
        })
    },[])
    return (
        <div className={style.accountInformation_body}>
           <Descriptions column={2} bordered>
                <Descriptions.Item label="公 司 名">{data.accountBankName}</Descriptions.Item>
                <Descriptions.Item label="服务费率">{data.serviceRate}</Descriptions.Item>
                <Descriptions.Item label="公司账户">{data.companyAccount}</Descriptions.Item>
                <Descriptions.Item label="打款户名">{data.paymentAccountName}</Descriptions.Item>
                <Descriptions.Item label="开户行">{data.accountBank}</Descriptions.Item>
                <Descriptions.Item label="打款账户">{data.paymentAccount}</Descriptions.Item>
                <Descriptions.Item label="开 户 名">{data.accountBankName}</Descriptions.Item>
                <Descriptions.Item label="打款开户行">{data.serveAccountBank}</Descriptions.Item>
                <Descriptions.Item label="公司余额">{data.accountBalance}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}
export default AccountInformation