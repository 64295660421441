import React from 'react'
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import Login from './pages/login/Login'
import Home from './pages/views/Home'
const App = () => {
    return (
        <div>
            <Router>
                <Switch>
                    <Route path="/" component={Login} exact></Route>
                    <Route path="/Home" component={Home} ></Route>
                </Switch>
            </Router>
        </div>
    )

}
export default App