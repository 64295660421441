import React, {useEffect, useRef, useState} from 'react';
import {Form, message, Modal, Select} from 'antd';
import {getAllDept} from '../../../api/TaskSettlement';

const TaskSettlementSynchronization = (props) => {
    let {visible, onCancel, onOkFun, appId} = props
    const [list, setList] = useState([]);
    const id = useRef(appId);
    const onOk = () => {
        if (!id.current) {
            message.warning("请选择小程序")
        } else {
            onOkFun(id.current)
        }
    }


    /** 调用获取所有小程序的函数 */
    useEffect(() => {
        getList()
    }, [])

    /** 获取所有小程序 */
    const getList = async () => {
        const {data} = await getAllDept()
        if (data.code === 200) {
            let arr = []
            data.data.forEach(item => {
                let obj = {}
                obj.value = item.deptId
                obj.label = item.deptName
                arr.push(obj)
            })
            setList(arr)
        }
    }

    /** 获取所选择的小程序 */
    const onChange = (e) => {
        id.current = e
    }

    return (

        <div style={{background: '#fefef4', height: '200px', display: 'flex', alignItems: 'center'}}>
            <Modal title="同步小程序" visible={visible} onOk={onOk} onCancel={onCancel} getContainer={false}
                   okText='确定' cancelText="取消"
            >
                <Form
                    name="wrap"
                    labelCol={{
                        flex: '110px',
                    }}
                    labelAlign="left"
                    wrapperCol={{
                        flex: 1,
                    }}
                    colon={false}
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Form.Item label="请选择小程序：">
                        <Select
                            disabled={appId}
                            defaultValue={appId ? appId : '请选择小程序'}
                            style={{
                                width: 120,
                            }}
                            options={list}
                            onChange={onChange}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default TaskSettlementSynchronization