import React, {useState, useEffect, useRef} from 'react';
import {Modal, Steps, Button, message, Checkbox, Input, Table} from 'antd'
import {getSevList, importMsg} from '../../../api/TaskRelease'
import {importsExcel} from "../../../until/excel";
import {showLoading, hideLoading} from '../../../common/Xjkjload'
import './ImportPages.less'

const ImportPages = (props) => {
    let {visible, onOk, onCancel, onFinishs} = props
    const [chkList, setChkList] = useState([])
    const [dataList, setDataList] = useState([])
    const [flag, setFlag] = useState(true)
    const [upFlag, setUpflag] = useState(false)
    const [dataMsg, setDateMsg] = useState([])
    const [current, setCurrent] = useState(0);
    const [errDade, setErrDade] = useState([])
    const [disFlag, setDisFlag] = useState(false)
    const myRef = useRef()
    useEffect(() => {
        getSevList().then(res => {
            if (res.data.data) {
                setDataList(res.data.data)
            }
        })
    }, [])
    const next = () => {
        setCurrent(current + 1);
        setFlag(true)
    }
    const handleExcel = (e) => {
        if (e.target.value) {
            setUpflag(false)
            importsExcel(e).then(
                function (data) {
                    if (data && data.length > 0) {
                        let arr = []
                        for (let i = 1; i < data.length; i++) {
                            data[i]["key"] = i

                            if (data[i]["code"] != null) {
                                data[i]["code"] = data[i]["code"].toString().trim();
                            }
                            if (data[i]["name"] != null) {
                                data[i]["name"] = data[i]["name"].toString().trim();
                            }
                            if (data[i]["cardType"] != null) {
                                data[i]["cardType"] = data[i]["cardType"].toString().trim();
                            }
                            if (data[i]["phone_number"] != null) {
                                data[i]["phone_number"] = data[i]["phone_number"].toString().trim();
                            }
                            if (data[i]["idcard_number"] != null) {
                                data[i]["idcard_number"] = data[i]["idcard_number"].toString().trim();
                            }

                            arr.push(data[i])

                            if (data[i]["code"] && data[i]["name"] && data[i]["cardType"]
                                && caedTypeReg.test(data[i]["idcard_number"]) && iphoneReg.test(data[i]["phone_number"])
                            ) {

                            } else {
                                setUpflag(true)
                            }
                        }
                        myRef.current.state.value = ""
                        setDateMsg(arr);
                    }
                },
            )
        }

    }
    var iphoneReg = /^1\d{10}$/
    var caedTypeReg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
            render: (text, record) =>
                (record.name && record.cardType && caedTypeReg.test(record.idcard_number) && iphoneReg.test(record.phone_number))
                    ? (<span>{text}</span>) : (<span style={{color: "red"}}>{text}</span>)
            ,

        },
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '证件号码',
            dataIndex: 'cardType',
            render: text =>
                <a>{text == 1 ? "身份证" : text == 2 ? "港澳台通行证" : text == 3 ? "护照" : text == 4 ? "军官证" : ""}</a>,
        },
        {
            title: '身份证号码',
            dataIndex: 'idcard_number',
            render: text => <a>{caedTypeReg.test(text) ? (<span>{text}</span>) : (
                <span style={{color: "red"}}>{text}</span>)}</a>,
        },
        {
            title: '手机号码',
            dataIndex: 'phone_number',
            render: text => <a>{iphoneReg.test(text) ? (<span>{text}</span>) : (
                <span style={{color: "red"}}>{text}</span>)}</a>,
        },
        {
            title: '银行卡号',
            dataIndex: 'bank_account',
        },
        {
            title: '编码',
            dataIndex: 'code',
        },
    ];
    const columns1 = [
        {
            title: '序号',
            dataIndex: 'key',
            render: (text, record) =>
                (record.name && record.cardType && caedTypeReg.test(record.idcard_number) && iphoneReg.test(record.phone_number))
                    ? (<span>{text}</span>) : (<span style={{color: "red"}}>{text}</span>)
            ,

        },
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '证件号码',
            dataIndex: 'cardType',
            render: text =>
                <a>{text == 1 ? "身份证" : text == 2 ? "港澳台通行证" : text == 3 ? "护照" : text == 4 ? "军官证" : ""}</a>,
        },
        {
            title: '身份证号码',
            dataIndex: 'idcard_number',
            render: text => <a>{caedTypeReg.test(text) ? (<span>{text}</span>) : (
                <span style={{color: "red"}}>{text}</span>)}</a>,
        },
        {
            title: '手机号码',
            dataIndex: 'phone_number',
            render: text => <a>{iphoneReg.test(text) ? (<span>{text}</span>) : (
                <span style={{color: "red"}}>{text}</span>)}</a>,
        },
        {
            title: '银行卡号',
            dataIndex: 'bank_account',
        },
        {
            title: '编码',
            dataIndex: 'code',
        },
        {
            title: '错误原因',
            dataIndex: 'errmsg',
        },
    ];
    const prev = () => {
        setCurrent(current - 1);
    };
    const onUpFan = () => {
        if (dataMsg.length) {
            let merchantServeId = window.localStorage.getItem("merchantServeId");
            let newAll = JSON.parse(JSON.stringify(dataMsg))
            for (let i = 0; i < newAll.length; i++) {
                delete newAll[i]['key']
            }
            let newAll1 = JSON.stringify(newAll)
            let arr1 = JSON.parse(JSON.stringify(chkList))
            let arr2 = []
            for (let i = 0; i < arr1.length; i++) {
                arr2.push(arr1[i])
            }
            let chkList1 = arr2.toString()
            let obj = {merchantServeId, serveType: chkList1, resultInfo: newAll1}
            showLoading()
            importMsg(obj).then(res => {
                hideLoading()
                if (res) {
                    let arr = []
                    for (let i = 0; i < res.data.data.data.length; i++) {
                        res.data.data.data[i]["key"] = i + 1
                        arr.push(res.data.data.data[i])
                    }
                    console.log(arr)
                    setErrDade(arr)
                    next()
                }
            }).catch(
                error =>
                    hideLoading()
            )

        } else {
            message.warn("请上传有效文件")
        }
    }
    const {Step} = Steps;
    const remove = (checkList, val) => {
        var index = checkList.indexOf(val);
        if (index > -1) {
            checkList.splice(index, 1);
        }
        return checkList
    }
    useEffect(() => {
        if (chkList.length) {
            setFlag(false)
        } else {
            setFlag(true)
        }
        // if(chkList.length==3){
        //     setDisFlag(true)
        // }else{
        //     setDisFlag(false)
        // }
    }, [chkList])
    const steps = [
        {
            title: '请选择服务类型',
            content: <div>
                {
                    dataList.map((item) => {
                        return (<Checkbox onChange={(e) => {
                            let checkList = JSON.parse(JSON.stringify(chkList))
                            if (e.target.checked) {
                                checkList.push(item.code)
                            } else {
                                remove(checkList, item.code)
                            }
                            setChkList(checkList)
                        }} key={item.code}
                                          disabled={chkList.indexOf(item.code) != -1 ? false : disFlag}>{item.name}</Checkbox>)
                    })
                }
            </div>,
        },
        {
            title: '人才导入',
            content: <div>
                <div>
                    <Input ref={myRef} type="file" accept=".xls,.xlsx" onChange={handleExcel}
                           style={{width: 200, marginRight: 10}}></Input>
                    <Button type="primary" disabled={upFlag} onClick={onUpFan}>确定无误后，上传此表格数据</Button>
                </div>
                <div style={{maxHeight: "400px", overflowY: "auto"}}>
                    <Table columns={columns} dataSource={dataMsg}/>
                </div>
            </div>,
        },
        {
            title: '完成',
            content: <div>
                {
                    errDade.length ? (<div><h2>上传失败数据：</h2>
                        <div style={{maxHeight: "400px", overflowY: "auto"}}>
                            <Table columns={columns1} dataSource={errDade}/>
                        </div>
                    </div>) : (<h2>数据上传成功</h2>)
                }
            </div>,
        },
    ];
    return (
        <Modal title="人才导入" visible={visible} onOk={onOk} onCancel={onCancel} width={1000} footer={null}>
            <Steps current={current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} size="small"/>
                ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()} disabled={flag}>
                        下一步
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={onCancel}>
                        已完成
                    </Button>
                )}
            </div>

        </Modal>
    )
}
export default ImportPages
