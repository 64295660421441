import {axios} from "./axios"
import qs from 'qs'
const modifyPwd =(data)=>{
    return  axios({
        method:"post",
        url:"/api/mgr/modifyPwd",
        data:qs.stringify(data)
    })
}
const checkPhone =(data)=>{
    return  axios({
        method:"post",
        url:"/api/kaptcha/checkPhone",
        data:qs.stringify(data)
    })
}
const changeAccount =(data)=>{
    return  axios({
        method:"post",
        url:"/api/kaptcha/changeAccount",
        data:qs.stringify(data)
    })
}
const getMerchantPhoneInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantServeInfo/getMerchantPhoneInfo",
        data:qs.stringify(data)
    })
}
export {modifyPwd, getMerchantPhoneInfo,checkPhone,changeAccount}