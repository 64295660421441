import React, {useState, useEffect} from 'react';
import style from './Onlinelnvoicing.module.less'
import {Row, Col, DatePicker, Space, Button, Radio, Card, Input, message, Select, Table, Tag, Tabs} from 'antd';
import {getDataList} from "../../api/OnlineInvoicing"
import OnlineDetails from "./components/OnlineDetails"
import KaiPiao from "./components/KaiPiao"
import JiChu from "./components/JiChu"
import ShenHei from "./components/ShenHei"
import moment from 'moment';
import {
    PaperClipOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import qs from 'qs'
import {showLoading, hideLoading} from "../../common/Xjkjload"

const InvoicingApproval = () => {
    const [isKaiPiaoModalVisible, setIsKaiPiaoModalVisible] = useState(false);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [isJiChuModalVisible, setIsJiChuModalVisible] = useState(false)
    const [isShenHeiModalVisible, setsIShenHeiModalVisible] = useState(false)
    const [time, setTime] = useState([undefined, undefined])
    const dateFormat = 'YYYY-MM-DD' || undefined;
    const {Option} = Select
    const [dataList, setDataList] = useState([])
    const {RangePicker} = DatePicker;
    const [keys, setKeys] = useState("-1")
    const {TabPane} = Tabs;
    const [records, setRecords] = useState({})
    useEffect(() => {
        let obj = {}
        obj.page = 1;
        obj.limit = 10;
        let newValue = qs.stringify(obj)
        showLoading()
        getDataList(newValue).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    }, [])
    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
    }
    const handleOkKp = () => {
        setIsKaiPiaoModalVisible(false);
    };
    const handleCancelKp = () => {
        setIsKaiPiaoModalVisible(false);
    };
    const showModalKp = (records) => {
        setRecords(records)
        setIsKaiPiaoModalVisible(true);
    };
    const handleOkJC = () => {
        setIsJiChuModalVisible(false);
    };
    const handleCancelJC = () => {
        onFinishss()
        setIsJiChuModalVisible(false);
    };
    const showModalJC = (records) => {
        setRecords(records)
        setIsJiChuModalVisible(true);
    };
    const handleOkSH = () => {
        onFinishss()
        setsIShenHeiModalVisible(false);
    };
    const handleCancelSH = () => {
        onFinishss()
        setsIShenHeiModalVisible(false);
    };
    const showModalSH = (records) => {
        setRecords(records)
        setsIShenHeiModalVisible(true);
    };
    const handleOks = () => {
        setIsDetailsModalVisible(false);
    };

    const handleCancels = () => {
        setIsDetailsModalVisible(false);
    };
    const showModals = (records) => {
        setRecords(records)
        setIsDetailsModalVisible(true);
    };
    const onFinishss = async (page = 1, limit = 10) => {
        let values = {}
        values.startTime = time[0];
        values.endTime = time[1];
        values.page = page;
        if (keys != "-1") {
            values.invoiceStatus = keys
        }
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    }
    const onFinish = async (page = 1, limit = 10, type = "-1") => {
        let values = {}
        values.startTime = time[0];
        values.endTime = time[1];
        values.page = page;
        if (type != "-1") {
            values.invoiceStatus = type
        }
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    }
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    const callback = (key) => {
        setKeys(key)
        onFinish(1, 10, key)
    }
    const columns = [
        {
            title: '编号',
            dataIndex: 'merchantInvoiceId',
            align: 'center',
        },
        {
            title: '开票单位',
            dataIndex: 'enterpriseName',
            align: 'center',
        },
        {
            title: '申请开票时间',
            dataIndex: 'applyTime',
            align: 'center',
        },
        {
            title: '申请开票金额',
            dataIndex: 'invoiceAmount',
            align: 'center',
        },
        {
            title: '开票类目',
            dataIndex: 'invoiceCategory',
            align: 'center',
        },
        {
            title: '开票状态',
            dataIndex: 'invoiceStatus',
            align: 'center',
        },
        {
            title: '驳回原因',
            dataIndex: 'rejectReason',
            align: 'center',
        },
        {
            title: '操作',
            width: '400px',
            align: 'center',
            render: (text, record) =>
                <div>
                    <Button style={{marginRight: "5px"}} onClick={() => showModals(record)}>申请详情</Button>
                    {
                        record.invoiceStatus
                        == "审核通过" ? (<Button style={{marginRight: "5px"}}
                                             onClick={() => showModalKp(record)}>开票详情</Button>) : ''
                    }
                    {
                        record.invoiceStatus
                        == "发票寄出" ? (
                            <Button style={{marginRight: "5px"}}
                                    onClick={() => showModalKp(record)}>开票详情</Button>) : record.invoiceStatus
                        == "审核中" ? (
                            <Button style={{marginRight: "5px"}}
                                    onClick={() => showModalSH(record)}>审核</Button>) : record.invoiceStatus
                        == "审核通过" ? (
                            <Button style={{marginRight: "5px"}} onClick={() => showModalJC(record)}>寄出发票</Button>

                        ) : ""
                    }
                </div>
            ,
        },
    ];
    const setTimesFun = (value) => {
        if (value == 2) {
            let startDate = moment().startOf('month').format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 1) {
            let startDate = moment().day(moment().day() - 6).format('YYYY-MM-DD') // 当前时间往前推一周的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 3) {
            let startDate = moment().subtract(1, 'quarters').format('YYYY-MM-DD ') // 当前时间往前推三个月的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const onFinishs = () => {
        onFinishss()
    }
    return (
        <div className={style.OnlineInvo}>
            <div>
                <Row style={{marginTop: 15}} justify="space-between">
                    <Col>
                        <Row gutter={10}>
                            <Col>

                                <Select placeholder="请选择" allowClear onChange={setTimesFun}>
                                    <Option value="1">
                                        最近一周
                                    </Option>
                                    <Option value="2">
                                        最近一月
                                    </Option>
                                    <Option value="3">
                                        最近三月
                                    </Option>
                                </Select>

                            </Col>
                            <Col>
                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>

                            </Col>
                            <Col>
                                <Button type="primary" onClick={onFinishs}>查询</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: "10px"}}>
                <Tabs onChange={callback} type="card">
                    <TabPane tab="全部" key="-1">
                    </TabPane>
                    <TabPane tab="审核中" key="1">
                    </TabPane>
                    <TabPane tab="审核驳回" key="2">
                    </TabPane>
                    <TabPane tab="审核通过" key="3">
                    </TabPane>
                    <TabPane tab="发票寄出" key="4">
                    </TabPane>
                </Tabs>
                <Table columns={columns} dataSource={dataList.records} pagination={{
                    total: dataList.total,
                    onChange: pageOnChange,
                    showTotal: (total) => `共 ${total} 条`,
                    current: dataList.current
                }}></Table>
            </div>
            {
                isDetailsModalVisible ? (
                    <OnlineDetails visible={isDetailsModalVisible} onOk={handleOks} onCancel={handleCancels}
                                   records={records}/>) : ""
            }
            {
                isKaiPiaoModalVisible ? (
                    <KaiPiao visible={isKaiPiaoModalVisible} onOk={handleOkKp} onCancel={handleCancelKp}
                             records={records}/>) : ""
            }
            {
                isJiChuModalVisible ? (<JiChu visible={isJiChuModalVisible} onOk={handleOkJC} onCancel={handleCancelJC}
                                              records={records}/>) : ""
            }
            {
                isShenHeiModalVisible ? (
                    <ShenHei visible={isShenHeiModalVisible} onOk={handleOkSH} onCancel={handleCancelSH}
                             records={records}/>) : ""
            }
        </div>
    )
}
export default InvoicingApproval
