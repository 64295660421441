import React from "react"
import {  Descriptions,Modal } from 'antd';
const OnlineDetails =(props)=>{
    let { visible, onOk, onCancel ,records} = props
    return <>
    <Modal  title="申请详情" visible={visible} onOk={onOk} onCancel={onCancel} width={1000} footer={null}>
    <Descriptions bordered column={1} size="small">
    <Descriptions.Item label="开票单位">{records.enterpriseName}</Descriptions.Item>
    <Descriptions.Item label="购货方名称">{records.enterpriseName}</Descriptions.Item>
    <Descriptions.Item label="纳税人识别号">{records.enterpriseTaxNumber}</Descriptions.Item>
    <Descriptions.Item label="地址、电话">{records.enterpriseAddress+""+records.enterprisePhone}</Descriptions.Item>
    <Descriptions.Item label="开户行及账号">{records.openBank+""+records.bankAccount}</Descriptions.Item>
    <Descriptions.Item label="开票类别">{records.invoiceCategory}</Descriptions.Item>
    <Descriptions.Item label="开票分类">{records.invoiceType}</Descriptions.Item>
    <Descriptions.Item label="开票金额">{records.invoiceAmount}</Descriptions.Item>
    <Descriptions.Item label="备注信息">{records.invoiceRemark}</Descriptions.Item>
    <Descriptions.Item label="注明">税务分类编号名称会显示并打印再发票上，请正确填写</Descriptions.Item>
    <Descriptions.Item label="收件地址">{records.receiveAddress}</Descriptions.Item>
    <Descriptions.Item label="收件人">{records.receiveName}</Descriptions.Item>
    <Descriptions.Item label="手机号">{records.receivePhone}</Descriptions.Item>
    </Descriptions>
    </Modal>
    </>
}
export default OnlineDetails
