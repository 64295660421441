import React from "react"
import { Modal, Form, Input, Row, Col, Button ,message} from 'antd';
import {upBh} from "../../../api/OnlineInvoicing"
import qs from 'qs'
import {showLoading,hideLoading } from "../../../common/Xjkjload"
const JiChu = (props) => {
    let { visible, onOk, onCancel, records } = props
    const [form] = Form.useForm();
    const upOkFun =async () => {
        let values = await form.validateFields();
        values.merchantInvoiceId=records.merchantInvoiceId
        values.invoiceStatus="4"
        let newValues=qs.stringify(values)
        showLoading()
        upBh(newValues).then(res=>{
            hideLoading()
            if(res.data.success){
                message.success(res.data.message)
                onCancel()
            }else{
                message.warn(res.data.message)
            }
        })
    }
    return <>
        <Modal title="寄出发票" visible={visible} onOk={onOk} onCancel={onCancel} width={600} footer={<Button type="primary" onClick={upOkFun}>确定</Button>}>
            <Form autoComplete="off"
                form={form}
            >
                <Row>
                    <Col span={20}>
                        <Form.Item
                            name="trackingNumber"
                            label="物流信息"
                            rules={[{ required: true, message: '请输入发票信息!' }]}

                        >
                            <Input placeholder="请输入" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}
export default JiChu
