import React, {useEffect, useState} from "react"
import {Descriptions, Row, Col, Modal, Checkbox, Input, message, Button} from 'antd';
import style from "./KaiPiao.module.less"
import {upBh} from "../../../api/OnlineInvoicing"
import {showLoading, hideLoading} from "../../../common/Xjkjload"
import qs from 'qs'

const KaiPiao = (props) => {
    let {visible, onOk, onCancel, records} = props
    const [useFlag, setUseFlag] = useState(false)
    const [checkedTg, setCheckedTg] = useState(false)
    const [checkedBh, setCheckedBh] = useState(false)
    const [values, setValues] = useState("")
    const opPdf = () => {
        let values = {fileId: records.invoicePhotoId};
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);

    }
    useEffect(() => {
        if (records) {
            if (records.flag) {
                setUseFlag(true)
            }
        }
    }, [records])
    const onValue = (e) => {
        setValues(e.target.value)
    }
    const onChangeTg = (e) => {
        setCheckedTg(e.target.checked)
        if (e.target.checked) {
            setCheckedBh(false)
        }
    }
    const onChangeBh = (e) => {
        setCheckedBh(e.target.checked)
        if (e.target.checked) {
            setCheckedTg(false)
        }
    }
    const upOkFun = () => {
        if (!checkedTg && !checkedBh) {
            message.warn("请确定是否通过")
        } else {
            let obj = {}
            if (checkedTg) {
                obj.merchantInvoiceId = records.merchantInvoiceId
                obj.invoiceStatus = "5"
                let newValues = qs.stringify(obj)
                showLoading()
                upBh(newValues).then(res => {
                    hideLoading()
                    if (res.data.success) {
                        message.success(res.data.message)
                        onCancel()
                    } else {
                        message.warn(res.data.message)
                    }
                })
            } else {
                obj.merchantInvoiceId = records.merchantInvoiceId
                obj.invoiceStatus = "6"
                obj.rejectReason = values
                let newValues = qs.stringify(obj)
                showLoading()
                upBh(newValues).then(res => {
                    hideLoading()
                    if (res.data.success) {
                        message.success(res.data.message)
                        onCancel()
                    } else {
                        message.warn(res.data.message)
                    }
                })
            }

        }

    }
    return <>
        <Modal title="开票详情" visible={visible} onOk={onOk} onCancel={onCancel} width={600} footer={null}>
            <Descriptions column={1}>
                <Descriptions.Item label="数目">1</Descriptions.Item>
                <Descriptions.Item label="发票">
                    <div className={style.PdfImg} onClick={opPdf}></div>
                </Descriptions.Item>
                {records.trackingNumber ? (<Descriptions.Item label="物流信息">{records.trackingNumber}</Descriptions.Item>) : ""}
            </Descriptions>
            {
                useFlag ? (
                    <>
                        <Row>
                            <Col>
                                <Checkbox onChange={onChangeTg} style={{width: "120px"}}
                                          checked={checkedTg}>复核通过</Checkbox>
                            </Col>
                            <Col>
                                <Checkbox onChange={onChangeBh} style={{width: "120px"}}
                                          checked={checkedBh}>复核驳回</Checkbox>
                            </Col>
                        </Row>
                        {
                            checkedBh ? (<Input placeholder="请输入驳回理由" onChange={onValue}></Input>) : ""
                        }
                        <Button type="primary" onClick={upOkFun} style={{marginTop: "20px"}}>确定</Button></>
                ) : ""

            }
        </Modal>
    </>
}
export default KaiPiao
