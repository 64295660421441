import React, {useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Input, message, Radio, Row, Space} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import style from './HomePage.module.less'
import './HomePage.css'
import {getComMsg} from '../../api/HomePage'
import moment from 'moment'
import qs from 'qs'
import {hideLoading, showLoading} from "../../common/Xjkjload"

const HomePage = () => {
    const {RangePicker} = DatePicker;
    const [useMsg, setUseMsg] = useState({})
    const [useMsgs, setUseMsgs] = useState({})
    const [jinr1, setJine1] = useState(null)
    const [jinr2, setJine2] = useState(0)
    const [jinr3, setJine3] = useState(0)
    const [timeDate, setTimeDate] = useState({})
    let merchantServeId = window.localStorage.getItem("merchantServeId");
    let isShowRate = window.localStorage.getItem("isShowRate");
    let merchantType = window.localStorage.getItem("merchantType");
    useEffect(() => {
        //  merchantServeId = window.localStorage.getItem("merchantServeId");
        // console.log(merchantServeId)
        if (merchantServeId) {
            let obj = {merchantServeId}
            let newValues = qs.stringify(obj)
            getComMsg(newValues).then(res => {
                if (res.data.data) {
                    setUseMsg(res.data.data)
                    setUseMsgs(res.data.data)
                }
            })
        }
    }, [merchantServeId])
    const setInput = (e) => {
        setJine1(e.target.value)
    }
    const setValue = () => {
        if (jinr1 == null) {
            message.warn('请输入结算金额')
        } else {
            if (jinr1 * 1 < 0) {
                message.warn('结算金额不能为负数')
            } else {
                let feiLv = useMsg.serviceRate
                feiLv = feiLv.replace("%", "") / 100;
                let num1 = jinr1 * feiLv
                let roundedNumber = Math.round(num1 * Math.pow(10, 2)) / Math.pow(10, 2)
                setJine3(roundedNumber)
                let num2 = num1 + jinr1 * 1
                let roundedNumber2 = Math.round(num2 * Math.pow(10, 2)) / Math.pow(10, 2)
                setJine2(roundedNumber2)
            }
        }
    }
    const setTime = (data, dateString) => {
        // let merchantServeId = window.localStorage.getItem("merchantServeId");
        let startTime = dateString[0]
        let endTime = dateString[1]
        let obj = {merchantServeId, startTime, endTime}
        setTimeDate(obj)
    }
    const onBty = () => {
        if (JSON.stringify(timeDate) === '{}') {
            // let merchantServeId = window.localStorage.getItem("merchantServeId");
            let obj = {merchantServeId}
            let newValues = qs.stringify(obj)
            showLoading()
            getComMsg(newValues).then(res => {
                hideLoading()
                if (res.data.data) {
                    setUseMsg(res.data.data)
                }
            })
        } else {
            let newValues = qs.stringify(timeDate)
            showLoading()
            getComMsg(newValues).then(res => {
                hideLoading()
                if (res.data.data) {
                    setUseMsg(res.data.data)
                }
            })
        }
    }
    const onJinRi = () => {
        // let merchantServeId = window.localStorage.getItem("merchantServeId");
        let startTime = moment().format('YYYY-MM-DD')
        let endTime = moment().format('YYYY-MM-DD')
        let obj = {merchantServeId, startTime, endTime}
        let newValues = qs.stringify(obj)
        showLoading()
        getComMsg(newValues).then(res => {
            hideLoading()
            if (res.data.data) {
                setUseMsg(res.data.data)
            }
        })

    }
    const onBenYue = () => {
        // let merchantServeId = window.localStorage.getItem("merchantServeId");
        let startTime = moment().startOf('month').format('YYYY-MM-DD')
        let endTime = moment().format('YYYY-MM-DD')
        let obj = {merchantServeId, startTime, endTime}
        let newValues = qs.stringify(obj)
        showLoading()
        getComMsg(newValues).then(res => {
            hideLoading()
            if (res.data.data) {
                setUseMsg(res.data.data)
            }
        })
    }
    const onQuanBu = () => {
        // let merchantServeId = window.localStorage.getItem("merchantServeId");
        let obj = {merchantServeId}
        let newValues = qs.stringify(obj)
        showLoading()
        getComMsg(newValues).then(res => {
            hideLoading()
            if (res.data.data) {
                setUseMsg(res.data.data)
            }
        })
    }
    return (<div className={style.HomePage}>
        {
            merchantType == "lingtu" ? "" : (<div className={style.HomePage_header}>
                <Row>
                    <Col className="searchTime">
                        <Space direction="vertical" size={12}>
                            <RangePicker locale={locale} onChange={setTime}/>
                        </Space>
                        <Button type="primary" style={{marginLeft: "15px"}} onClick={onBty}>查询</Button>
                    </Col>
                    <Col className="searchButton">
                        <Radio.Group style={{marginLeft: "20px"}} defaultValue="c" buttonStyle="solid">
                            <Radio.Button value="a" onClick={onJinRi}>今日</Radio.Button>
                            <Radio.Button value="b" onClick={onBenYue}>本月</Radio.Button>
                            <Radio.Button value="c" onClick={onQuanBu}>全部</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
            </div>)
        }
        <div className={style.HomePage_context}>
            <Row gutter={16}>
                <Col span={6}>
                    <Card
                        title={useMsg.merchantType === 'lingtu' ? (useMsg.isContainFee === 0 ? '账户余额(可实发金额):' : '账户余额(含服务费金额):') : '账户余额:'}
                        bordered={false} style={{background: "#f7f8fa"}}>
                        {useMsg.accountBalance}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="下发金额" bordered={false} style={{background: "#f7f8fa"}}>
                        {useMsg.paymentAmount}
                    </Card>
                </Col>
                {
                    isShowRate == "1" ?
                        <Col span={6}> <Card title="服务费" bordered={false} style={{background: "#f7f8fa"}}>
                            {useMsg.serviceCharge}
                        </Card></Col> : <div/>
                }
                {
                    isShowRate == "1" ?
                        <Col span={6}> <Card title="充值金额" bordered={false} style={{background: "#f7f8fa"}}>
                            {useMsg.rechargeAmount}
                        </Card></Col> : <div/>
                }
            </Row>
        </div>
        <div className={style.HomePage_calculator}>
            <Row>
                <Col span={4} className={style.HomePage_titles}>充值计算器</Col>
                <Col span={6} offset={12}><Input placeholder="请输入结算金额" size="large" onChange={setInput}/></Col>
                <Col span={2}><Button size="large" className={style.HomePage_bty} block="true"
                                      onClick={setValue}>计算</Button></Col>
            </Row>
        </div>
        <div className={style.HomePage_footer}>
            <div className={style.HomePage_footer_left}>
                <div className={style.HomePage_footer_left_P}>
                    需充值金额：
                </div>
                <div className={style.HomePage_footer_left_P1}>
                    {jinr2.toFixed(2)}
                </div>
                <div className={style.HomePage_footer_left_P2}>
                    服务费：<span style={{color: "#67757c"}}>{jinr3.toFixed(2)}</span>元
                </div>
            </div>
            <div className={style.HomePage_footer_right2}>
                <div className={style.HomePage_footer_left_P}>
                    服务费率：
                </div>
                <div className={style.HomePage_footer_left_P1}>
                    {useMsgs.serviceRate}
                </div>
                <div className={style.HomePage_footer_left_P2}>
                    打款户名：<span style={{color: "#67757c"}}>{useMsgs.paymentAccountName}</span>
                </div>
                <div className={style.HomePage_footer_left_P2}>
                    打款账户：<span style={{color: "#67757c"}}>{useMsgs.paymentAccount}</span>
                </div>
                <div className={style.HomePage_footer_left_P2}>
                    开户行：<span style={{color: "#67757c"}}>{useMsgs.accountBank}</span>
                </div>

                {
                    useMsg.isAlipay == "1" ?
                        (<div>
                                <div className={style.HomePage_footer_left_P2}>
                                    支行：<span style={{color: "#67757c"}}>{useMsgs.cardBranch}</span>
                                </div>
                                <div className={style.HomePage_footer_left_P2}>
                                    联行号：<span style={{color: "#67757c"}}>{useMsgs.cardDeposit}</span>
                                </div>
                                <div className={style.HomePage_footer_left_P2}>
                                    开户地址：<span style={{color: "#67757c"}}>{useMsgs.cardLocation}</span>
                                </div>
                            </div>
                        )
                        : ""
                }

            </div>
        </div>

    </div>);
}
export default HomePage
