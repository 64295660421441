import React, {useEffect, useState} from "react"
import {Row, Col, Button, Descriptions, Form, Input, message, Select, DatePicker, Space, Table, Tag, Modal} from 'antd';
import {addData, setFeiLei, getDetail} from "../../../api/OnlineInvoicing"
import qs from 'qs'
import {showLoading, hideLoading} from "../../../common/Xjkjload"
import {getMerchantPhoneInfo} from "../../../api/BindAccount"

const OnlinePage = (props) => {
    let {visible, onOk, onCancel, dataJr, setPhone, setValues} = props
    const [form] = Form.useForm();
    const [dataList, setDataList] = useState([])
    const {Option} = Select;
    let merchantServeId = window.localStorage.getItem("merchantServeId");
    const onFinshFn = async () => {
        let values = await form.validateFields();
        if (values.invoiceAmount > dataJr.residueMoney || values.invoiceAmount <= 0) {
            message.warn("开票金额不能大于可开票金额,且不能为0")
            return
        }
        values.merchantServeId = merchantServeId
        setValues(values)
        let data = window.localStorage.getItem('merchantServeId')
        let param = {
            merchantServeId: data
        }
        getMerchantPhoneInfo(param).then(res => {
            setPhone(res.data.data.phone)
        })
        onOk()
        // let newValues = qs.stringify(values)
        // showLoading()
        // addData(newValues).then(res=>{
        //     hideLoading()
        //   if(res.data.success){
        //       message.success(res.data.message)
        //       onCancel()
        //       onFinishs()
        //   }else{
        //       message.warn("保存失败")
        //   }
        // },)
    }
    useEffect(() => {
        let obj = {dictTypeId: "1460858573339951105"}
        let obj1 = {merchantServeId}
        let newValues = qs.stringify(obj)
        let newObj1 = qs.stringify(obj1)
        setFeiLei(newValues).then(res => {
            setDataList(res.data.data)
        })
        getDetail(newObj1).then(res => {
            let obj = {
                enterpriseName: res.data.data.merchantName,
                enterprisePhone: res.data.data.merchantPhone,
                enterpriseTaxNumber: res.data.data.creditCode,
                openBank: res.data.data.accountBankName,
                enterpriseAddress: res.data.data.merchantAddress,
                bankAccount: res.data.data.companyAccount,
                receiveAddress:res.data.data.recipientAddress,
                receiveName:res.data.data.recipient,
                receivePhone:res.data.data.recipientPhone,
            }
            form.setFieldsValue(obj)
        })
    }, [])

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    return <>
        <Modal title="申请开票" visible={visible} onOk={onOk} onCancel={onCancel} width={1200} footer={null}>
            <Form autoComplete="off"
                  form={form}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="enterpriseName"
                            label="企业名称"
                            rules={[{required: true, message: '请输入企业名称!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="enterprisePhone"
                            label="企业电话"
                            rules={[{required: true, message: '请输入企业电话!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="enterpriseTaxNumber"
                            label="企业税号"
                            rules={[{required: true, message: '请输入企业名称!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="openBank"
                            label="开户行名称"
                            rules={[{required: true, message: '请输入企业电话!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="enterpriseAddress"
                            label="企业地址"
                            rules={[{required: true, message: '请输入企业地址!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="bankAccount"
                            label="银行账号"
                            rules={[{required: true, message: '请输入银行账号!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="invoiceCategory"
                            label="开票类目"
                            rules={[{required: true, message: '请输入开票类目!'}]}

                        >
                            <Select
                                showSearch
                                placeholder="请选择"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    dataList.length ? (dataList.map(item => {
                                        return (
                                            <Option key={item.code} value={item.code}>{item.name}</Option>
                                        )
                                    })) : ""
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="invoiceAmount"
                            label="开票金额"
                            rules={[{required: true, message: '请输入开票金额!'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="invoiceType"
                            label="开票类型"
                            rules={[{required: true, message: '请输入开票类型!'}]}

                        >
                            <Select placeholder="请选择">
                                <Option value={1}>增值税专用发票</Option>
                                <Option value={0}>普通发票</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="invoiceRemark"
                            label="发票右小角备注信息"

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="receiveAddress"
                            label="收件地址"
                            rules={[{required: true, message: '请输入收件地址'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="receiveName"
                            label="收件人"
                            rules={[{required: true, message: '请输入收件地址'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name="receivePhone"
                            label="收件人电话"
                            rules={[{required: true, message: '请输入收件人电话'}]}

                        >
                            <Input placeholder="请输入"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Button type="primary" onClick={onFinshFn}>保存</Button>
            </Form>
        </Modal>
    </>
}
export default OnlinePage
