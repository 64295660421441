import axios, { AxiosPromise } from "axios";
import {hideLoading, showLoading} from "../common/Xjkjload";
//不用baseUrl了  用反向代理
//请求头拦截
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    if (window.localStorage.getItem("token")) {
        config.headers.common["Authorization"] = window.localStorage.getItem("token");
    }
    showLoading()
    return config;
}, error => {
    // 对请求错误做些什么
    hideLoading()
    return Promise.reject(error);
});

//响应头拦截
axios.interceptors.response.use(response => {
    hideLoading()
    return response;
}, error => {
    hideLoading()
    if (error.response) {
        switch (error.response.status) {
            // 返回401，清除token信息并跳转到登录页面
            case 401:
            window.localStorage.removeItem("token")
            window.location.href="#/"
                break;
            case 404:
                // alert("跳到错误页面");
                break;
            case 500:
                // alert("接口调用错误")
                break;
        }
        // 返回接口返回的错误信息
        return Promise.reject(error.response.data);
    }
});

export {
    axios, AxiosPromise
}
