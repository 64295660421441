import {axios} from "./axios"
import qs from 'qs'
const taskSettlementInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/pageList",
        data:qs.stringify(data)
    })
}
const subTaskSettleInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/subTaskSettleInfo",
        data:data
    })
}
// 任务结算确认发放（POST）
const saveTaskSettleInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/saveTaskSettleInfo",
        data:qs.stringify(data)
    })
}
// 任务结算明细
const settleDetailInfo =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementFlow/flowInfo",
        data:qs.stringify(data)
    })
}
const downloadPdf =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementFlow/download_pdf",
        data:qs.stringify(data)
    })
}
// 任务作废
const cancelItem =(data)=>{
    return  axios({
        method:"post",
        url:"/api/taskSettlementInfo/cancelItem",
        data:qs.stringify(data)
    })
}

//  获取所有小程序
const getAllDept =(data)=>{
  return  axios({
      method:"get",
      url:"/api/taskSettlementFlow/getAllDept",
      // data:qs.stringify(data)
  })
}

//  同步小程序
const addTaskReward =(data)=>{
  return  axios({
      method:"post",
      url:"/api/taskSettlementFlow/addTaskReward",
      data:qs.stringify(data)
  })
}

export {taskSettlementInfo, subTaskSettleInfo, saveTaskSettleInfo, settleDetailInfo, cancelItem,downloadPdf,getAllDept,addTaskReward}