import React, {useState, useEffect} from 'react'
import style from "./Home.module.less"
import {Menu, Button, Breadcrumb, Layout, Form, Input, Row, Col, Select, Dropdown, message} from 'antd';
import HomePage from './HomePage';
import BindAccount from './BindAccount';
import AccountInformation from './AccountInformation'
import BindingContract from './BindingContract'
import DistributionList from './DistributionList'
import OnlineInvoicing from './OnlineInvoicing'
import RechargeDetails from './RechargeDetails'
import TaskRelease from './TaskRelease'
import TaskSettlement from './TaskSettlement'
import TaskSettlementList from './TaskSettlementList'
import SystemInformation from './SystemInformation'
import ApprovalCenter from './ApprovalCenter'
import InvoicingApproval from './InvoicingApproval'
import UserManagement from './UserManagement'
import {HashRouter as Router, Route, Switch, useHistory} from "react-router-dom"
import qs from 'qs'
import {getUseMsg, getOut, getLogo} from '../../api/Home'
import {showLoading, hideLoading} from "../../common/Xjkjload"
import {
    MailOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FileSearchOutlined,
    UserOutlined,
    ContainerOutlined,
    RedoOutlined,
    BellFilled,
    ReconciliationOutlined
} from '@ant-design/icons';

const {SubMenu} = Menu;
const {Header, Sider, Content, Footer} = Layout;
const {Option} = Select;
const Home = () => {
    const history = useHistory()
    const [form] = Form.useForm();
    const [isImg, setIsImg] = useState(true)
    const [collapsed, setCollapsed] = useState(false)
    const [flag, setFlag] = useState(true)
    const [userName, setUserName] = useState("暂无名称")
    const [oneTitle, setOneTitle] = useState('首页')
    const [twoTitle, setTwoTitle] = useState('')
    const [menuFlag, setMenuFlag] = useState(Number)
    const [merList, setMerList] = useState([])
    const [ftSize, setFtSize] = useState("20px")
    const [keyName, setKeyName] = useState(["1"])
    const [keyF, setKeyF] = useState([])
    const [serList, setSerList] = useState([])
    const [logoName, setLogoName] = useState("薪津科技")
    const [logoImg, setLogoImg] = useState("https://download.027xjkj.com/download/1470278640989114369.png")
    const [menuList, setMenuList] = useState([{
        key: '1',
        menuTitle: "首页",
        menuName: '首页',
        pathName: "#/Home/HomePage"
    }, {key: '2', menuTitle: "操作中心", menuName: '任务发布', pathName: "#/Home/TaskRelease"}, {
        key: '3',
        menuTitle: "操作中心",
        menuName: '任务结算',
        pathName: "#/Home/TaskSettlement"
    }, {key: '4', menuTitle: "操作中心", menuName: '任务结算清单', pathName: "#/Home/TaskRelease"}, {
        key: '5',
        menuTitle: "查询中心",
        menuName: '充值明细',
        pathName: "#/Home/RechargeDetails"
    }, {key: '6', menuTitle: "查询中心", menuName: '发放列表', pathName: "#/Home/DistributionList"}, {
        key: '7',
        menuTitle: "个人中心",
        menuName: '账号信息',
        pathName: "#/Home/AccountInformation"
    }, {key: "8", menuTitle: "个人中心", menuName: '绑定合同', pathName: "#/Home/BindingContract"}, {
        key: '9',
        menuTitle: "个人中心",
        menuName: '绑定账号',
        pathName: "#/Home/BindAccount"
    }, {key: '10', menuTitle: "开票中心", menuName: '在线开票', pathName: "#/Home/OnlineInvoicing"}, {
        key: '11',
        menuTitle: "管理中心",
        menuName: '用户管理',
        pathName: "#/Home/UserManagement"
    }, {key: '12', menuTitle: "管理中心", menuName: '审批中心', pathName: "#/Home/ApprovalCenter"}, {
        key: '13',
        menuTitle: "管理中心",
        menuName: '开票审核',
        pathName: "#/Home/InvoicingApproval"
    }])
    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    };
    const menuChange = (data) => {
        let {menuTitle, menuName} = menuList.filter(item => item.key === data.key)[0]
        if (data.key === '1') {
            setFlag(true)
            setKeyF([])
        } else {
            setFlag(false)
            setKeyF(data.keyPath[1])
        }
        setOneTitle(menuTitle)
        setTwoTitle(menuName)
        window.location.keyname = data.key
        let arr1 = []
        arr1.push(data.key)
        setKeyName(arr1)
    }
    useEffect(() => {
        if (logoName.length <= 4) {
            setFtSize("20px")
        } else if (logoName.length == 5) {
            setFtSize("20px")
        } else if (logoName.length == 6) {
            setFtSize("18px")
        } else if (logoName.length == 7) {
            setFtSize("16px")
        } else if (logoName.length == 8) {
            setFtSize("15px")
        } else if (logoName.length == 9) {
            setFtSize("14px")
        } else if (logoName.length == 10) {
            setFtSize("12px")
        }

    }, [logoName])
    const getOutFun = () => {
        showLoading()
        getOut().then(
            res => {
                hideLoading()
                if (res.data.success) {
                    message.success(res.data.message)
                    window.localStorage.removeItem("merchantServeId");
                    window.localStorage.removeItem("merchantType");
                    window.localStorage.removeItem("token");
                    window.localStorage.removeItem("ptChecker");
                    window.localStorage.removeItem("isShowRate");
                    history.push('/')
                } else {
                    message.warn(res.data.message)
                }
            }
        )
    }
    const handleImageLoaded = () => {
        setIsImg(true)
    }

    const handleImageErrored = () => {
        setIsImg(false)
    }
    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={getOutFun}>
                退出登录
            </Menu.Item>
        </Menu>)
    useEffect(() => {
        const {hostname} = window.location;
        if (hostname == "localhost") {
            setLogoName("")
            setLogoImg("https://download.027xjkj.com/download/1610808328982630401.png")
            // setLogoName("薪津科技")
            // setLogoImg("https://download.027xjkj.com/download/1470278640989114369.png")
        } else {
            let a = hostname.indexOf(".")
            let name = hostname.slice(0, a)
            if (name == "www" || name == "salary") {
                setLogoName("")
                setLogoImg("https://download.027xjkj.com/download/1610808328982630401.png")
                // logoImgs.href = "https://download.027xjkj.com/download/1470278640989114369.png"
                document.title = "薪津科技"
            } else {
                let obj = {domainName: name}
                getLogo(obj).then(res => {
                    if (res.data.success) {
                        setLogoName(res.data.data.domainTitle)
                        setLogoImg(res.data.data.domainLogo)
                        document.title = res.data.data.domainTitle
                    }
                })
            }
        }
        const {hash} = window.location;
        if (hash != "#/Home") {
            let arr1 = []
            let arr = menuList.filter(item => item.pathName == hash)
            console.log(arr)
            if (arr.length) {
                let akey = arr[0].key
                if (akey == 1) {
                    setFlag(true)
                } else {
                    setFlag(false)
                }
                setOneTitle(arr[0].menuTitle)
                setTwoTitle(arr[0].menuName)
                arr1.push(akey)
            }
            setKeyName(arr1)
        }
        getUseMsg().then(res => {
            if (res.data.data) {
                setUserName(res.data.data.userName)
                if (res.data.data.permissions === 'admin') {
                    //平台管理员
                    setMenuFlag(1)
                    history.push('/Home/ApprovalCenter')
                } else if (res.data.data.permissions === 'user' || res.data.data.permissions === "ptChecker") {
                    //普通用户
                    if (res.data.data.permissions === "ptChecker") {
                        window.localStorage.setItem("ptChecker", "1");
                    } else {
                        window.localStorage.setItem("ptChecker", "2");
                    }
                    setMenuFlag(2)
                }
                if (res.data.data.MerchantServeInfo) {

                    let {merchantId, serveCompanyId, merchantServeId, isShowRate, merchantType} = res.data.data.MerchantServeInfo
                    setMerList(res.data.data.merchantServiceList)
                    window.localStorage.setItem("merchantServeId", merchantServeId);
                    window.localStorage.setItem("merchantType", merchantType);
                    window.localStorage.setItem("isShowRate", isShowRate);
                    let newList = res.data.data.merchantServiceList.filter(item => item.merchantId == merchantId)[0].serviceList
                    setSerList(newList)
                    form.setFieldsValue({merchantId, serveCompanyId})
                }
            }
        })
    }, [])
    // useEffect(() => {

    // }, [menuFlag])
    const setCompany = async () => {
        let obj = form.getFieldsValue()
        let newValues = qs.stringify(obj)
        showLoading()
        let res = await getUseMsg(newValues)
        hideLoading()
        if (res.data.data && res.data.data.MerchantServeInfo) {
            let {merchantServeId, merchantType} = res.data.data.MerchantServeInfo
            window.localStorage.setItem("merchantServeId", merchantServeId);
            window.localStorage.setItem("merchantType", merchantType);
        }
        window.location.reload()
        // history.push('/Home')
        // setTimeout(()=>{window.location.reload()}, 1000 )
    }
    const onSelectMer = (value) => {
        let newList = merList.filter(item => item.merchantId == value)
        setSerList(newList[0].serviceList)
        let {serveCompanyId} = newList[0].serviceList[0]
        form.setFieldsValue({serveCompanyId})

    }
    return (
        <Layout className={style.Home_body}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                {/* <div className={collapsed ? style.Home_body_logo1 : style.Home_body_logo} /> */}

                {
                    !collapsed ? (<div className={style.Home_body_title}><img src={logoImg}
                                                                              onLoad={handleImageLoaded.bind(this)}
                                                                              onError={handleImageErrored.bind(this)}
                                                                              className={isImg ? style.Home_body_logo1 : style.hideImage}></img>
                            { (logoName != '' ? <h2 className={style.Home_body_h2}
                                                    style={{fontSize: ftSize}}>{logoName}</h2> : '')}
                        </div>) :
                        <div className={style.Home_body_title}><img src={logoImg}
                                                                    onLoad={handleImageLoaded.bind(this)}
                                                                    onError={handleImageErrored.bind(this)}
                                                                    className={isImg ? style.Home_body_logo1 : style.hideImage}></img>
                        </div>
                }
                {/* <img src={logoImg} className={style.Home_body_logo1}></img>
                <h2 className={style.Home_body_h2}>{logoName}</h2> */}

                <Menu
                    selectedKeys={keyName}
                    mode="inline"
                    theme="dark"
                    onClick={menuChange}
                    defaultOpenKeys={["sub5", "sub1", "sub2", "sub3", "sub4"]}
                >
                    {
                        menuFlag == 1 ? (<SubMenu key="sub5" icon={<ReconciliationOutlined/>} title="管理中心">
                            {/* <Menu.Item key="11"><a href="#/Home/UserManagement">用户管理</a></Menu.Item> */}
                            <Menu.Item key="12"><a href="#/Home/ApprovalCenter">审批中心</a></Menu.Item>
                            <Menu.Item key="13"><a href="#/Home/InvoicingApproval">开票审核</a></Menu.Item>
                        </SubMenu>) : menuFlag == 2 ? (
                            <>
                                <Menu.Item key="1" icon={<HomeOutlined/>}>
                                    <a href="#/Home/HomePage">首页</a>
                                </Menu.Item>
                                <SubMenu key="sub1" icon={<MailOutlined/>} title="操作中心">
                                    <Menu.Item key="2"><a href="#/Home/TaskRelease">任务发布</a></Menu.Item>
                                    <Menu.Item key="3"><a href="#/Home/TaskSettlement">任务结算</a></Menu.Item>
                                    <Menu.Item key="4"><a href="#/Home/TaskSettlementList">任务结算清单</a></Menu.Item>
                                </SubMenu>
                                <SubMenu key="sub2" icon={<FileSearchOutlined/>} title="查询中心">
                                    <Menu.Item key="5"><a href="#/Home/RechargeDetails">充值明细</a></Menu.Item>
                                    <Menu.Item key="6"><a href="#/Home/DistributionList">发放列表</a></Menu.Item>
                                </SubMenu>
                                <SubMenu key="sub3" icon={<UserOutlined/>} title="个人中心">
                                    <Menu.Item key="7"><a href="#/Home/AccountInformation">账号信息</a></Menu.Item>
                                    {/* <Menu.Item key="8"><a href="#/Home/BindingContract">绑定合同</a></Menu.Item> */}
                                    <Menu.Item key="9"> <a href="#/Home/BindAccount">绑定账号</a></Menu.Item>
                                </SubMenu>
                                <SubMenu key="sub4" icon={<ContainerOutlined/>} title="开票中心">
                                    <Menu.Item key="10"><a href="#/Home/OnlineInvoicing">在线开票</a></Menu.Item>
                                </SubMenu>
                            </>
                        ) : ""
                    }
                </Menu>
            </Sider>
            <Layout>
                <Header className={style.Home_body_right_header}>
                    <Row>
                        <Col span={22}>
                            <Row>
                                <Col style={{textAlign: "center", width: '5%'}}>
                                    {
                                        collapsed ? (<MenuUnfoldOutlined onClick={toggleCollapsed}/>) : (
                                            <MenuFoldOutlined onClick={toggleCollapsed}/>)
                                    }
                                </Col>
                                {
                                    menuFlag == 2 ? (
                                        <Col style={{width: '95%'}}>
                                            <Form form={form}>
                                                <Row>
                                                    <Col style={{marginRight: '20px'}} span={8}>
                                                        <Form.Item
                                                            label="当前商户："
                                                            name="merchantId"
                                                            style={{lineHeight: "64px"}}
                                                            className={style.hearder_label}
                                                        >
                                                            <Select onSelect={onSelectMer}>
                                                                {
                                                                    merList.map(item => {
                                                                        return (<Option key={item.merchantId}
                                                                                        value={item.merchantId}>{item.merchantName}</Option>)
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col style={{marginRight: '20px'}} span={8}>
                                                        <Form.Item
                                                            label="服务公司："
                                                            name="serveCompanyId"
                                                            style={{lineHeight: "64px"}}
                                                            className={style.hearder_label}
                                                        >
                                                            <Select>
                                                                {
                                                                    serList.map(item => {
                                                                        return (<Option key={item.serveCompanyId}
                                                                                        value={item.serveCompanyId}>{item.serveCompanyName}</Option>)
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col style={{lineHeight: "64px"}}>
                                                        <Button type="primary" icon={<RedoOutlined/>}
                                                                onClick={setCompany}>切换公司</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    ) : ""
                                }

                            </Row>
                        </Col>
                        <Col span={2}>
                            <Row>
                                {/* <Col >
                                    <BellFilled className={style.Home_body_right_header_right_context_msg} size={30} onClick={() => {
                                        history.push('/Home/SystemInformation')
                                    }} />
                                </Col> */}
                                <Col>
                                    <Dropdown overlay={menu} placement="bottomCenter" arrow>
                                        <Button type="link" style={{color: "block"}}>{userName}</Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Content style={{margin: '0 16px'}}>
                    <Breadcrumb style={{margin: '8px 0'}}>
                        <Breadcrumb.Item>{oneTitle}</Breadcrumb.Item>
                        {
                            flag ? "" : (
                                <Breadcrumb.Item>{twoTitle}</Breadcrumb.Item>
                            )
                        }
                    </Breadcrumb>
                    <div className={style.Home_body_right_context} style={{
                        margin: '8px 16px 0 16px',
                        padding: 24,
                    }}>
                        <Router>
                            <Switch>
                                <Route path="/Home" component={HomePage} exact></Route>
                                <Route path="/Home/HomePage" component={HomePage}></Route>
                                <Route path="/Home/BindAccount" component={BindAccount}></Route>
                                <Route path="/Home/AccountInformation" component={AccountInformation}></Route>
                                <Route path="/Home/BindingContract" component={BindingContract}></Route>
                                <Route path="/Home/DistributionList" component={DistributionList}></Route>
                                <Route path="/Home/OnlineInvoicing" component={OnlineInvoicing}></Route>
                                <Route path="/Home/RechargeDetails" component={RechargeDetails}></Route>
                                <Route path="/Home/TaskRelease" component={TaskRelease}></Route>
                                <Route path="/Home/TaskSettlement" component={TaskSettlement}></Route>
                                <Route path="/Home/TaskSettlementList" component={TaskSettlementList}></Route>
                                <Route path="/Home/SystemInformation" component={SystemInformation}></Route>
                                <Route path="/Home/UserManagement" component={UserManagement}></Route>
                                <Route path="/Home/ApprovalCenter" component={ApprovalCenter}></Route>
                                <Route path="/Home/InvoicingApproval" component={InvoicingApproval}></Route>
                            </Switch>
                        </Router>
                    </div>
                </Content>
                <Footer>
                    <div className={style.Home_title_footer}>
                        <div className={style.Home_title_footer_div}>
                            <a target="_blank" className={style.Home_title_footer_div_a}
                               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004646"><img
                                className={style.Home_title_footer_div_img}/>
                                <p className={style.Home_title_footer_div_p}>鄂公网安备 42011102004646号</p>
                                <p className={style.Home_title_footer_div_p}>Copyright 薪津科技 All Rights Reserved</p>
                            </a>
                        </div>
                    </div>
                </Footer>
            </Layout>
        </Layout>
    )
}
export default Home
