import {axios} from "./axios"
const getUseMsg =(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantInfo/currentUserInfo",
        data
    })
}
const getOut =()=>{
    return  axios({
        method:"post",
        url:"/api/logout",
    })
}
const getLogo = (data)=>{
    return  axios({
        method:"post",
        url:"/api/domain/getLogo",
        params:data
    })
}
export {getUseMsg,getOut,getLogo}
