import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Table, Tabs} from 'antd';
import 'moment/locale/zh-cn';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {addTaskReward, cancelItem, taskSettlementInfo} from '../../api/TaskSettlement';
import TaskSettlementImport from './components/TaskSettlementImport'
import PhoneValid from './components/PhoneValid'
import {getMerchantPhoneInfo} from "../../api/BindAccount"
import TaskSettlementDetail from './components/TaskSettlementDetail'
import TaskSettlementSynchronization from './components/TaskSettlementSynchronization'
import {DownloadOutlined, PaperClipOutlined} from '@ant-design/icons';
import qs from 'qs';

const TaskSettlement = () => {
    const [tongBu, setTongBu] = useState(false)  //  弹框的显示状态
    const tongBuData = useRef({});               //   点击同步后获取的那一列表格的数据
    const [listState, setListState] = useState(false)  //  是否通知发放明细刷新页面
    const {Option} = Select
    const {RangePicker} = DatePicker;
    const [data, setData] = useState([])
    const {TabPane} = Tabs;
    const [phone, setPhone] = useState("")
    const [showTotal, setShowTotal] = useState(null)
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const [batchStatus, setBatchStatus] = useState()
    const [paymentStatus, setPaymentStatus] = useState()
    const [time, setTime] = useState([undefined, undefined])
    const [form] = Form.useForm();
    const [taskBalanceId, setTaskBalanceId] = useState('')
    const dateFormat = 'YYYY-MM-DD' || undefined;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);
    const [isProvide, setIsProvide] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [isDetail, setIsDetail] = useState(false)
    // 下载模板
    const getXlsx = () => {
        let url = 'https://www.027xjkj.com/任务结算模板.xlsx'
        window.location.href = url
    }
    const handlePhoneOk = () => {
        onFinish()
        setIsPhoneModalVisible(false)
    }
    const downLoad = (id) => {
        let values = {fileId: id};
        if (qs.stringify(values) === "") {
            message.warn("未上传文件");
            return;
        }
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);
    }
    const handlePhoneCancel = () => {
        setIsPhoneModalVisible(false)
    }
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const submitPro = (row) => {
        let data = window.localStorage.getItem('merchantServeId')
        let param = {
            merchantServeId: data
        }
        setTaskBalanceId(row.taskBalanceId)
        getMerchantPhoneInfo(param).then(res => {
            setIsPhoneModalVisible(true)
            setPhone(res.data.data.phone)
        })
    }

    /** 打开同步小程序弹框 */
    const synchronization = async (row) => {
        setTongBu(true)
        //  获取表格数据
        tongBuData.current = row
    }

    /** 关闭小程序同步弹框 */
    const handleTongBuCancel = (row) => {
        setTongBu(false)
    }

    /** 在发放明细页面打开同步小程序弹框 */
    const detailSynchronization = () => {
        setTongBu(true)
    }

    /** 重置发放明细获取列表的状态 */
    const onListState = () => {
        setListState(!listState)
    }

    /** 同步小程序 */
    const onTongBu = async (id) => {
        let obj = {
            taskBalanceId: tongBuData.current.taskBalanceId,
            deptId: id
        }
        const {data} = await addTaskReward(obj)
        if (data.code === 200) {
            message.success(data.data)
            setTongBu(false)
            // 发放明细刷新页面
            setListState(!listState)
            tongBuData.current.synchronizeDeptId = id
        } else {
            message.error(data.message)
        }
    }

    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const showModal = () => {
        setIsModalVisible(true);
    };
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    const setStatusFun = (value) => {
        setPaymentStatus(value)
    }
    const setTimesFun = (value) => {
        if (value == 2) {
            let startDate = moment().startOf('month').format('YYYY-MM-DD')
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 1) {
            let startDate = moment().day(moment().day() - 6).format('YYYY-MM-DD') // 当前时间往前推一周的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else if (value == 3) {
            let startDate = moment().subtract(1, 'quarters').format('YYYY-MM-DD ') // 当前时间往前推三个月的时间
            let endDate = moment().format('YYYY-MM-DD')
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const onFinishs = () => {
        onFinish()
    }
    const onFinish = async (page = 1, limit = 10) => {
        let values = await form.validateFields();
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = window.localStorage.getItem("merchantServeId")
        values.page = page;
        values.limit = limit;
        values.batchStatus = batchStatus;
        values.paymentStatus = paymentStatus;
        taskSettlementInfo(values).then(res => {
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                    res.data.data.records[i]["confirmVisible"] = false
                }
            }
            setData(res.data.data)
            setShowTotal(res.data.data.total)
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const callback = (key) => {
        let merchantServeId = window.localStorage.getItem('merchantServeId')
        let param = {}
        if (key === '1') {
            setBatchStatus('')
            param = {
                page: 1,
                limit: 10,
                merchantServeId: merchantServeId
            }
        } else {
            setBatchStatus(9)
            param = {
                page: 1,
                limit: 10,
                merchantServeId: merchantServeId,
                batchStatus: 9
            }
        }
        taskSettlementInfo(param).then(res => {
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setData(res.data.data)
            setShowTotal(res.data.data.total)
        })
    };

    /**点击查看明细记录id 记录所点击列表的数据 */
    const settlementDetail = (record) => {
        setTaskBalanceId(record.taskBalanceId)
        tongBuData.current = record
        console.log("<<<", tongBuData.current);
        setIsDetail(true)
    };
    const showPopconfirm = (record) => {
        record.confirmVisible = true
    };
    const confirmPop = (record) => {
        cancelItem({taskBalanceId: record.taskBalanceId}).then(res => {
            message.success(res.data.message);
            onFinish()
            record.confirmVisible = false
        })
    };
    const cancelPop = (record) => {
        message.success('已取消作废');
        record.confirmVisible = false
    };
    useEffect(() => {
        let merchantServeId = window.localStorage.getItem('merchantServeId')
        let param = {
            page: 1,
            limit: 10,
            merchantServeId: merchantServeId
        }
        taskSettlementInfo(param).then(res => {
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setData(res.data.data)
            setShowTotal(res.data.data.total)
        })
    }, [])
    const columns = [
        {
            title: '任务名称',
            dataIndex: 'taskName',
            key: 'taskName',
            align: 'center'
        },
        {
            title: '结算单',
            dataIndex: 'fileId',
            key: 'fileId',
            align: 'center',
            render: (data, row) => (
                <Button onClick={() => downLoad(row.fileId)}
                        style={{color: 'rgb(103, 194, 58)', border: 'none'}}>
                    <DownloadOutlined/>
                </Button>
            )
        },
        {
            title: '总金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'center',
            render: (data, row) => {
                return (
                    <div>
                        <div>总金额：{row.totalAmount}</div>
                        <div>人员数：{row.personNum}</div>
                    </div>
                )
            }
        },
        {
            title: '成功金额',
            dataIndex: 'succcessAmount',
            key: 'succcessAmount',
            align: 'center',
            render: (data, row) => (
                <span>{row.batchStatus !== '0' ? row.succcessAmount : '--'}</span>
            )
        },
        {
            title: '失败金额',
            dataIndex: 'failAmount',
            key: 'failAmount',
            align: 'center',
            render: (data, row) => (
                <span>{row.batchStatus !== '0' ? row.failAmount : '--'}</span>
            )
        },
        {
            title: '发放状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            align: 'center',
        },
        {
            title: '批次状态',
            dataIndex: 'batchStatusName',
            key: 'batchStatusName',
            align: 'center',
            render: (data, row) => (
                <span>{row.batchStatus === '3' ? row.auditRemarks : row.batchStatusName}</span>
            )
        },
        {
            title: '创建时间',
            dataIndex: 'settlementCreateTime',
            key: 'settlementCreateTime',
            align: 'center',
        },
        {
            title: '提交时间',
            dataIndex: 'settlementSubmitTime',
            key: 'settlementSubmitTime',
            align: 'center',
        },
        {
            title: '备注',
            dataIndex: 'auditRemarks',
            key: 'auditRemarks',
            align: 'center',
        },
        {
            title: '操作',
            align: 'center',
            render: (text, record) => (
                //0 待提交 1 发放中 2 发放成功 3 发放失败 4 部分失败
                <span>
          <Button disabled={record.paymentStatus !== '0' || ptCheckerFlag == 1}
                  onClick={() => submitPro(record)} style={{
              backgroundColor: record.paymentStatus === '0' ? '#f56c6c' : record.paymentStatus === '1' ? '#1890ff' : record.paymentStatus === '2' ? '#67c23a' : '#f56c6c',
              color: '#fff'
          }}>{!batchStatus ? record.paymentStatus === '0' ? '提交发放' : record.paymentStatusName : ''}</Button>
          <Popconfirm
              title="确定需要作废吗"
              onConfirm={() => confirmPop(record)}
              onCancel={cancelPop}
              disabled={record.confirmVisible}
              okText="确定"
              cancelText="取消"
              disabled={ptCheckerFlag == 1 ? true : false}
          >
            {!batchStatus && (record.paymentStatus === '0' || record.paymentStatus === '3') ?
                <Button onClick={() => showPopconfirm(record)}
                        style={{backgroundColor: '#f56c6c', color: '#fff'}}
                        disabled={ptCheckerFlag == 1 ? true : false}>
                    作废
                </Button> : ''}
          </Popconfirm>
                    {((record.paymentStatus === '2' || record.paymentStatus === '3' || record.paymentStatus === '4') && !batchStatus) ?
                        <Button style={{marginLeft: '10px', color: '#fff'}} type="primary" onClick={() => {
                            settlementDetail(record)
                        }}>
                            发放明细
                        </Button> : ''}
                    {/* {!batchStatus ? record.paymentStatus === '2'||record.paymentStatus === '4' ? '同步' : record.paymentStatusName : ''} */}
                    <Button
                        onClick={() => synchronization(record)} style={{
                        backgroundColor: '#f56c6c',
                        color: '#fff', marginLeft: '10px'
                    }}>{!batchStatus && record.paymentStatus === '2' || record.paymentStatus === '4' ? '同步' : ''}</Button>
        </span>
            ),
        },
    ];

    return (
        <div>
            <div style={{display: isDetail ? 'none' : 'block'}}>
                <Row>
                    <Col> <Button type="primary" icon={<PaperClipOutlined/>}
                                  onClick={showModal}
                                  disabled={ptCheckerFlag == 1 ? true : false}>结算单Excel导入</Button></Col>
                    <Col> <Button type="link" onClick={getXlsx}> 批次任务结算模板下载 </Button></Col>
                </Row>
                <Form
                    initialValues={{remember: true}}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={10} style={{marginTop: 15}}>
                        <Col>
                            <Form.Item
                                label="名称"
                                name="taskName"
                            >
                                <Input placeholder="请输入结算名称"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Select placeholder="请选择" allowClear onChange={setTimesFun}>
                                <Option value="1">
                                    最近一周
                                </Option>
                                <Option value="2">
                                    最近一月
                                </Option>
                                <Option value="3">
                                    最近三月
                                </Option>
                            </Select>
                        </Col>
                        <Col>
                            <Form.Item
                                name="usertime"
                            >
                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Select placeholder="结算状态" allowClear onChange={setStatusFun}>
                                <Option value="0">
                                    待提交
                                </Option>
                                <Option value="1">
                                    发放中
                                </Option>
                                <Option value="2">
                                    发放成功
                                </Option>
                                <Option value="3">
                                    发放失败
                                </Option>
                                <Option value="4">
                                    部分失败
                                </Option>
                            </Select>
                        </Col>

                        <Col>
                            <Button type="primary" onClick={onFinishs}>查询</Button>
                        </Col>
                    </Row>
                </Form>
                <Tabs defaultActiveKey="1" onChange={callback} type="card">
                    <TabPane tab="正常批次" key="1">
                        <Table columns={columns} dataSource={data.records} pagination={{
                            total: data.total,
                            onChange: pageOnChange,
                            showTotal: (total) => `共 ${total} 条`,
                            current: data.current
                        }}/>
                    </TabPane>
                    <TabPane tab="已作废" key="2">
                        <Table columns={columns} dataSource={data.records} pagination={{
                            total: data.total,
                            onChange: pageOnChange,
                            showTotal: (total) => `共 ${total} 条`,
                            current: data.current
                        }}/>
                    </TabPane>
                </Tabs>
            </div>
            {
                errorMsg !== "" ? (<Modal visible={errorMsg !== ""} title={errorMsg} closable={false} okText='确定' cancelText="取消"
                                          onOk={()=>{
                                             setErrorMsg("")
                                         }}
                                          onCancel={
                                             ()=>{
                                                 setErrorMsg("")
                                             }
                                         }/>) : ""
            }
            {
                isPhoneModalVisible ? (<PhoneValid visible={isPhoneModalVisible} phone={phone} onOk={handlePhoneOk}
                                                   onCancel={handlePhoneCancel} taskBalanceId={taskBalanceId}
                                                   setErrorMsg={setErrorMsg}
                                                   setIsProvide={setIsProvide} onFinish={onFinish}/>) : ""
            }
            {
                isModalVisible ? (<TaskSettlementImport visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                                                        onFinish={onFinish}/>) : ""
            }
            {
                isDetail ? (<TaskSettlementDetail row={tongBuData.current} taskBalanceId={taskBalanceId}
                                                  setIsDetail={setIsDetail} listState={listState}
                                                  onListState={onListState}
                                                  detailSynchronization={detailSynchronization}/>) : ''
            }
            {
                tongBu ? (<TaskSettlementSynchronization visible={tongBu}
                                                         onCancel={handleTongBuCancel} onOkFun={onTongBu}
                                                         appId={tongBuData.current.synchronizeDeptId}/>) : ''
            }
        </div>
    )
}
export default TaskSettlement
