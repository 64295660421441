import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Table, Tabs} from 'antd';
import {getListS} from '../../api/TaskSettlementList'
import SpShei from '../../pages/views/components/SpShei'
import {hideLoading, showLoading} from "../../common/Xjkjload"
import qs from 'qs'
import {DownloadOutlined} from '@ant-design/icons';

const ApprovalCenter = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isShenHeiModalVisible, setsIShenHeiModalVisible] = useState(false)
    const [data, setData] = useState([])
    const [dataFlag, setDataFlag] = useState(null)
    const {TabPane} = Tabs;
    const [countPage, setCountPage] = useState(null)
    const [records, setRecords] = useState({})
    const [key, setKey] = useState(2)
    useEffect(() => {
        getList(2)

    }, [])
    const pageOnChange = (page, pageSize) => {
        getList(key, page, pageSize)
    }
    const callback = (key) => {
        setKey(key)
        getList(key)
    }
    const downLoadSettlement = (id) => {
        let values = {fileId: id};
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);
    }
    const downLoadService = (id) => {
        let values = {fileId: id};
        window.open(`https://www.027xjkj.com:9666/download?${qs.stringify(values)}`);
    }
    const handleOkSH = () => {
        getList(key)
        setsIShenHeiModalVisible(false);
    };
    const handleCancelSH = () => {
        getList(key)
        setsIShenHeiModalVisible(false);
    };
    const showModalSH = (records) => {
        setDataFlag(true)
        setRecords(records)
        setsIShenHeiModalVisible(true);
    };
    const showModalSHs = () => {
        setDataFlag(false)
        setsIShenHeiModalVisible(true);
    };

    const getList = (key, page = 1, limit = 10) => {
        setCountPage(page)
        let param = {
            page: page,
            limit: limit,
            batchStatus: key || 1
        }
        showLoading()
        getListS(param).then(res => {
            hideLoading()
            console.log(res)
            if (res.data.data) {
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i]["key"] = i + 1
                }
            }
            setData(res.data)
        }).catch(() => {
            hideLoading()
        })
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRows)
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'taskBalanceId',
            width: 100,
            ellipsis: true,
            align: 'center',
        },
        {
            title: '商户公司',
            dataIndex: 'merchantName',
            align: 'center',
            ellipsis: true
        },
        {
            title: '服务公司',
            dataIndex: 'serveCompanyName',
            align: 'center',
            ellipsis: true
        },
        {
            title: '确认函',
            dataIndex: 'age',
            render: (data, row) => {
                return (
                    <div>
                        {row.settlementId ? <DownloadOutlined onClick={() => downLoadSettlement(row.settlementId)}
                                                              style={{color: 'green'}}/> : ''}
                    </div>
                )
            },
            align: 'center',
            width: 100,
            ellipsis: true
        },
        {
            title: '结算清单',
            dataIndex: 'serviceFeeId',
            render: (data, row) => {
                return (
                    <div>
                        {row.serviceFeeId ? <DownloadOutlined onClick={() => downLoadService(row.serviceFeeId)}
                                                              style={{color: 'green'}}/> : ''}
                    </div>
                )
            },
            align: 'center',
            width: 100,
            ellipsis: true
        },
        {
            title: '任务名称',
            dataIndex: 'taskName',
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '成功金额',
            dataIndex: 'successAmount',
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '提交时间',
            dataIndex: 'settlementUpdateTime',
            align: 'center',
            ellipsis: true
        },
        {
            title: '结算状态',
            dataIndex: 'paymentStatus',  //0:待发放；1：发放中；2：已发放；3：发放失败
            render: (type, record) => {
                return (
                    <>
                        {
                            type == 0 ? "待发放" : type == 1 ? "发放中" : type == 2 ? "已发放" : type == 3 ? "发放失败" : ""
                        }
                    </>
                )
            },
            width: 100,
            align: 'center',
            ellipsis: true
        },
        {
            title: '审核状态',
            dataIndex: 'batchStatus',
            render: (type, record) => {
                return (
                    <>
                        {
                            type == 1 ? "等待用户上传" : type == 2 ? "审核中" : type == 3 ? "审核失败" : type == 4 ? "审核通过" : ""
                        }
                    </>
                )
            },
            width: 100,
            align: 'center',
            ellipsis: true
        },

        {
            title: '操作',
            render: (text, record) =>
                <div>
                    <Button style={{marginRight: "10px"}} type="primary" onClick={() => showModalSH(record)}
                            disabled={record.batchStatus != 2}>审核</Button>
                </div>
            ,
            width: 100,
            align: 'center',
            ellipsis: true
        },
    ];
    return (
        <div>
            <Tabs onChange={callback} type="card" destroyInactiveTabPane>
                <TabPane tab="正在审核中" key="2">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.data} pagination={{
                        total: data.count,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: countPage
                    }}/>
                    <Row style={{marginTop: 50}}>
                        <Col> <Button type="primary" disabled={selectedRowKeys.length === 0}
                                      onClick={() => showModalSHs()}>批量审核</Button></Col>
                    </Row>
                </TabPane>
                <TabPane tab="等待用户上传" key="1">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.data} pagination={{
                        total: data.count,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: countPage
                    }}/>
                </TabPane>
                <TabPane tab="拒绝（需要重新上传）" key="3">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.data} pagination={{
                        total: data.count,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: countPage
                    }}/>
                </TabPane>
                <TabPane tab="已审核通过" key="4">
                    <Table rowSelection={rowSelection} columns={columns} dataSource={data.data} pagination={{
                        total: data.count,
                        onChange: pageOnChange,
                        showTotal: (total) => `共 ${total} 条`,
                        current: countPage
                    }}/>
                </TabPane>
            </Tabs>
            {
                isShenHeiModalVisible ? (
                    <SpShei visible={isShenHeiModalVisible} onOk={handleOkSH} onCancel={handleCancelSH}
                            records={records} dataFlag={dataFlag} selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}/>) : ""
            }
        </div>
    )
}
export default ApprovalCenter
