import React, { useState, useEffect } from "react"
import { Row, Col, Modal, Checkbox, Input, message } from 'antd';
import Button from "antd/es/button";
import { getSh } from "../../../api/TaskSettlementList"
const SpShei = (props) => {
    let { visible, onOk, onCancel, records, dataFlag, selectedRowKeys ,setSelectedRowKeys} = props
    const [checkedTg, setCheckedTg] = useState(false)
    const [checkedBh, setCheckedBh] = useState(false)
    const [values, setValues] = useState("")
    const onChangeTg = (e) => {
        setCheckedTg(e.target.checked)
        if (e.target.checked) {
            setCheckedBh(false)
        }
    }
    const onChangeBh = (e) => {
        setCheckedBh(e.target.checked)
        if (e.target.checked) {
            setCheckedTg(false)
        }
    }
    const onValue = (e) => {
        setValues(e.target.value)
    }
    const upOkFun = () => {
        if(!checkedTg&&!checkedBh){
            message.warn("请先选择是通过还是驳回")
            return
        }
        let obj = {}
        if (checkedTg) {
            if (dataFlag) {
                obj.taskBalanceId = records.taskBalanceId
            } else {
                obj.taskBalanceIds =""
                for (var i = 0; i < selectedRowKeys.length; i++) {
                    obj.taskBalanceIds += selectedRowKeys[i].taskBalanceId + ","
                }
            }
            obj.batchStatus = "4"
            getSh(obj).then(
                res => {
                    if (res.data.success) {
                        message.success(res.data.message)
                        setSelectedRowKeys([])
                        onCancel()
                    } else {
                        message.warn(res.data.message)
                    }
                }
            )
        } else {
            if (!values) {
                message.warn("请输入驳回理由")
            } else {

                if (dataFlag) {
                    obj.taskBalanceId = records.taskBalanceId
                } else {
                    obj.taskBalanceIds =""
                    for (var i = 0; i < selectedRowKeys.length; i++) {
                        obj.taskBalanceIds += selectedRowKeys[i].taskBalanceId + ","
                    }
                }
                obj.batchStatus = "3"
                obj.auditRemarks = values
                getSh(obj).then(
                    res => {
                        if (res.data.success) {
                            message.success(res.data.message)
                            onCancel()
                            setSelectedRowKeys([])
                        } else {
                            message.warn(res.data.message)
                        }
                    }
                )
            }
        }
    }
    return <>
        <Modal title="审核" visible={visible} onOk={onOk} onCancel={onCancel} width={500} footer={<Button type="primary" onClick={upOkFun}>确定</Button>}>
            <Row>
                <Col >
                    <Checkbox onChange={onChangeTg} style={{ width: "120px" }} checked={checkedTg}>通过</Checkbox>
                </Col>
                <Col >
                    <Checkbox onChange={onChangeBh} style={{ width: "120px" }} checked={checkedBh}>驳回</Checkbox>
                </Col>
            </Row>
            {
                checkedBh ? (<Input placeholder="请输入驳回理由" onChange={onValue}></Input>) : ""
            }
        </Modal>
    </>
}
export default SpShei