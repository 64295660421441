import {axios} from "./axios"
const getJr =(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantInvoiceInfo/getInvoiceMoney",
        data
    })
}
const getDataList =(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantInvoiceInfo/pageList",
        data
    })
}
const addData =(data)=>{
    return  axios({
        method:"post",
        url:"/api/merchantInvoiceInfo/addItem",
        data
    })
}
const upPdf=(data)=>{
    return  axios({
        method:"post",
        url:"/api/upload",
        data
    })
}
const upBh=(data)=>{
    return axios({
        method:"post",
        url:"api/merchantInvoiceInfo/editStatus",
        data
    })
}
const setFeiLei=(data)=>{
    return axios({
        method:"post",
        url:"api/dict/list",
        data
    })
}
const getDetail=(data)=>{
    return axios({
        method:"post",
        url:"api/merchantInfo/detailByServe",
        data
    })
}
export {getJr,getDataList,addData,upPdf,upBh,setFeiLei,getDetail}