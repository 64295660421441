import React, {useState, useEffect, useRef} from 'react';
import {Button, Modal, Input, Form, message} from 'antd';
import {getCode} from "../../../api/Login";
import qs from 'qs';
import {saveTaskSettleInfo} from '../../../api/TaskSettlement';
import {showLoading, hideLoading} from "../../../common/Xjkjload"

const PhoneValid = (props) => {
    let {visible, phone, onOk, onCancel, taskBalanceId, setIsProvide,setErrorMsg} = props
    const [time, setTime] = useState(60);
    const [btnContent, setBtnContent] = useState('获取验证码');
    const [btnDisabled, setBtnDisabled] = useState(false);
    let timeChange = useRef();
    const [form] = Form.useForm();
    const onOkFun = () => {
        let kaptcha = form.getFieldValue().kaptcha
        let merchantServeId = window.localStorage.getItem("merchantServeId");
        let params = {
            phone: phone,
            kaptcha: kaptcha,
            taskBalanceId: taskBalanceId,
            merchantServeId: merchantServeId
        }
        showLoading()
        saveTaskSettleInfo(params).then(res => {
            hideLoading()
            if (res.data.code === 200) {
                setIsProvide(true)
                message.success(res.data.message)
            } else {
                setErrorMsg(res.data.message)
            }
            onOk()
        }).catch(e => {
            hideLoading()
            message.error(e.message)
        })
    }
    const getCodeFun = async () => {
        let newValues = form.getFieldValue().phone
        let data = {
            phone: newValues
        }
        let res = await getCode(qs.stringify(data))
    }
    const getPhoneCaptcha = () => {
        getCodeFun().then(() => {
            // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
            timeChange.current = setInterval(() => setTime(t => --t), 1000);
            setBtnDisabled(true);
        })
    };
    const afterClose = () => {
        form.resetFields()
    }
    useEffect(() => {
        if (phone) {
            form.setFieldsValue({
                phone: phone
            })
        }
    }, [phone]);
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setBtnContent(`${time}s后重发`);
        } else {
            clearInterval(timeChange.current);
            setBtnContent("获取验证码")
            setBtnDisabled(false);
            setTime(60);
        }
    }, [time]);
    return (
        <div style={{background: '#fefef4', height: '200px', display: 'flex', alignItems: 'center'}}>
            <Modal title="验证手机" visible={visible} onOk={onOkFun} onCancel={onCancel} getContainer={false}
                   afterClose={afterClose}
                   okText='确定' cancelText="取消"
            >
                <Form
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="phone"
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input disabled size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="kaptcha"
                        rules={[{required: true, message: '请输入短信验证码!'}]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input size="large" placeholder="请输入验证码"
                               addonAfter={<Button type="link" disabled={btnDisabled} style={{color: 'black'}}
                                                   onClick={getPhoneCaptcha}>
                                   {btnContent}
                               </Button>}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
export default PhoneValid
