import {axios} from "./axios"
import qs from 'qs'
const getRenCaiMsg =(data)=>{
    return  axios({
        method:"post",
        url:"/api/signInfo/pageList",
        data
    })
}
const setExcel =(data)=>{
    return  axios({
        method:"post",
        url:"/api/excel/uploadExcel",
        data
    })
}
const getSevList=()=>{
    let obj={dictCode:"SIGN_SERVE_TYPE"}
    let newValues = qs.stringify(obj)
    return  axios({
        method:"post",
        url:"/api/signInfo/getSignTypeInfo",
        data:newValues
    })
}
const importMsg=(data)=>{
    let obj=data
    let newValues = qs.stringify(obj)
    return  axios({
        method:"post",
        url:"/api/signInfo/signInfoImport",
        data:newValues
    })
}
export {getRenCaiMsg,setExcel,getSevList,importMsg}
