import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Row, Select, Space, Table, Tag} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {getDataList} from "../../api/RechargeDetails"
import {FileTextOutlined} from '@ant-design/icons';
import qs from 'qs'
import moment from 'moment';
import {hideLoading, showLoading} from "../../common/Xjkjload"

const RechargeDetails = () => {
    const {Option} = Select
    const {RangePicker} = DatePicker;
    const [dataList, setDataList] = useState([])
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    const [time, setTime] = useState([undefined, undefined])
    const dateFormat = 'YYYY-MM-DD' || undefined;
    const [form] = Form.useForm();
    let merchantServeId = window.localStorage.getItem("merchantServeId");
    let isShowRate = window.localStorage.getItem("isShowRate");
    let merchantType = window.localStorage.getItem("merchantType");
    useEffect(() => {
        let obj = {page: 1, limit: 10, merchantServeId}
        let newValues = qs.stringify(obj)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })

    }, [])
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const onFinish = async (page = 1, limit = 10) => {
        let values = {}
        values.startTime = time[0];
        values.endTime = time[1];
        values.merchantServeId = merchantServeId
        values.page = page;
        values.limit = limit;
        let newValues = qs.stringify(values)
        showLoading()
        getDataList(newValues).then(res => {
            hideLoading()
            if (res.data.data.records) {
                for (let i = 0; i < res.data.data.records.length; i++) {
                    res.data.data.records[i]["key"] = i + 1
                }
            }
            setDataList(res.data.data)
        })
    };
    const pageOnChange = (page, pageSize) => {
        onFinish(page, pageSize)
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const selects = () => {
        var data = new Date();
        var year = data.getFullYear();
        var month = data.getMonth() + 1;
        var list = [];
        for (var i = 0; i < 12; i++) {
            list.push((<Option key={i} value={year.toString() + '-' + month.toString()}>
                {year}年{month}月
            </Option>))
            month = month - 1;
            if (month == 0) {
                year = year - 1;
                month = 12;
            }
        }
        return list;
    }
    const setTimeFun = (date, dateString) => {
        setTime(dateString)
    }
    const setTimesFun = (value) => {
        if (value) {
            var year = value.split('-')[0];
            var month = value.split('-')[1];
            console.log(year + '-' + month)
            let startDate = moment(value).startOf('month').format('YYYY-MM-DD')
            let endDate = moment(value).endOf('month').format('YYYY-MM-DD')
            console.log(startDate + '-' + endDate)
            setTime([startDate, endDate])
        } else {
            setTime([undefined, undefined])
        }
    }
    const onFinishs = () => {
        onFinish()
    }
    const onExportFun = () => {
        let values = {}
        values.startDate = time[0];
        values.endDate = time[1];
        values.merchantServeId = merchantServeId
        window.open(`/api/merchantServeFlow/exportInfoExcel?${qs.stringify(values)}`, {
            method: 'GET',
            Authorization: window.localStorage.getItem("token")
        });
    }
    const columns =
        isShowRate == "1" && merchantType != "lingtu" ?
            [
                {
                    title: '公司名称',
                    dataIndex: 'paymentAccountName',
                },
                {
                    title: '充值金额',
                    dataIndex: 'rechargeAmount',
                    render: count => {
                        return (
                            <Tag color="orange">
                                ￥{count}
                            </Tag>
                        )
                    }
                },
                {
                    title: '服务费',
                    dataIndex: 'serviceAmount',
                    render: account => {
                        return (
                            <Tag color="orange">
                                ￥{account}
                            </Tag>
                        )
                    }
                },
                {
                    title: '是否收取服务费',
                    dataIndex: 'isService',
                    render: (tags, record) => {
                        return (
                            <Tag style={{color: record.serviceAmount > 0 ? '#87d068' : '#f50'}}>
                                {record.serviceAmount > 0 ? "是" : "否"}
                            </Tag>
                        );
                    },
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                },
                {
                    title: '附属信息',
                    dataIndex: 'addresss',
                    render: (_, record) => {
                        if (record.receiveCompany == null) {
                            return (
                                <>
                                    <p>付款户名：{record.paymentAccountName}</p>
                                    <p>付款账号：{record.paymentAccount}</p>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <p>付款户名：{record.paymentAccountName}</p>
                                    <p>付款账号：{record.paymentAccount}</p>
                                    <p>收款户名：{record.receiveCompany}</p>
                                    <p>收款账号：{record.receiveBankAccount}</p>
                                </>
                            )
                        }
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'flowTime',
                },
            ] :
            [
                {
                    title: '公司名称',
                    dataIndex: 'paymentAccountName',
                },
                {
                    title: '充值金额',
                    dataIndex: 'rechargeAmount',
                    render: count => {
                        return (
                            <Tag color="orange">
                                ￥{count}
                            </Tag>
                        )
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                },
                {
                    title: '附属信息',
                    dataIndex: 'addresss',
                    render: (_, record) => {
                        if (record.receiveCompany == null) {
                            return (
                                <>
                                    <p>付款户名：{record.paymentAccountName}</p>
                                    <p>付款账号：{record.paymentAccount}</p>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <p>付款户名：{record.paymentAccountName}</p>
                                    <p>付款账号：{record.paymentAccount}</p>
                                    <p>收款户名：{record.receiveCompany}</p>
                                    <p>收款账号：{record.receiveBankAccount}</p>
                                </>
                            )
                        }
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'flowTime',
                },
            ];

    return (
        <div>
            <Row style={{marginTop: 15}} justify="space-between">
                <Col>
                    <Row gutter={10}>
                        <Col>

                            <Select placeholder="请选择" allowClear onChange={setTimesFun} style={{width: 120}}>
                                {selects()}
                            </Select>

                        </Col>
                        {merchantType == "lingtu" ? "" :
                            (<Col>
                                <Space direction="vertical" size={12}>
                                    <RangePicker locale={locale} onChange={setTimeFun}
                                                 value={time[0] === undefined || time[1] === undefined || time[0] === "" || time[1] === "" ? null : [moment(time[0], dateFormat), moment(time[1], dateFormat)]}/>
                                </Space>
                            </Col>)
                        }
                        <Col>
                            <Button type="primary" onClick={onFinishs}>查询</Button>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row gutter={10}>
                        <Col>
                            <Button type="primary" icon={<FileTextOutlined/>} onClick={onExportFun}>导出</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Table columns={columns} dataSource={dataList.records} pagination={{
                total: dataList.total,
                onChange: pageOnChange,
                showTotal: (total) => `共 ${total} 条`,
                current: dataList.current
            }} size="small" style={{marginTop: "10px"}}/>
        </div>
    )
}
export default RechargeDetails
