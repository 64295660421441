import React, {useState, useEffect, useRef} from 'react';
import {Button, Modal, Input, Form, message} from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import {getCode} from "../../api/Login";
import qs from 'qs';
import {modifyPwd, getMerchantPhoneInfo, checkPhone, changeAccount} from "../../api/BindAccount"
import {hideLoading, showLoading} from "../../common/Xjkjload";
import {getOut} from "../../api/Home";
import {useHistory} from "react-router-dom";

const BindAccount = () => {
    const history = useHistory()
    const [time, setTime] = useState(60);
    const [phone, setPhone] = useState("")
    const [newPhone, setNewPhone] = useState("")
    const [btnContent, setBtnContent] = useState('获取验证码');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [ptCheckerFlag, setPtCheckerFlag] = useState(null)
    let timeChange = useRef();
    const [form] = Form.useForm();
    const [isResetPasswdVisible, setisResetPasswdVisible] = useState(false)
    const [isChangeBindVisible, setisChangeBindVisible] = useState(false)
    const [isChangePhoneVisible, setisChangePhoneVisible] = useState(false)
    const openResetPasswd = () => {
        form.setFieldsValue({phone: phone})
        setisResetPasswdVisible(true)
    }
    const changeBindPhone = () => {
        form.setFieldsValue({phone: phone})
        setisChangeBindVisible(true)
    }
    const handleOk = () => {
        form.validateFields().then(values => {
            let data = {
                phone: values.phone,
                code: values.kaptcha,
                newPassword: values.password1
            }
            modifyPwd(data).then(res => {
                setisResetPasswdVisible(false)
            })
        }).catch(errorInfo => {
            console.log(errorInfo)
        })
    }
    const handleNext = () => {
        form.validateFields().then(values => {
            let data = {
                phone: values.phone,
                kaptcha: values.kaptcha,
            }
            checkPhone(data).then(res => {
                if (res.data.success) {
                    clearInterval(timeChange.current);
                    setBtnContent("获取验证码")
                    setBtnDisabled(false);
                    setTime(60);
                    form.setFieldsValue({oldPhone: phone})
                    setisChangePhoneVisible(true)
                } else {
                    message.warn(res.data.message)
                }
            }).catch(res => {
                message.warn(res.data.message)
            })
        }).catch(errorInfo => {
            console.log(errorInfo)
        })
    }
    const getOutFun = () => {
        getOut().then(
            res => {
                if (res.data.success) {
                    message.success(res.data.message)
                    window.localStorage.removeItem("merchantServeId");
                    window.localStorage.removeItem("merchantType");
                    window.localStorage.removeItem("token");
                    window.localStorage.removeItem("ptChecker");
                    window.localStorage.removeItem("isShowRate");
                    history.push('/')
                } else {
                    message.warn(res.data.message)
                }
            }
        )
    }
    useEffect(() => {
        var ptChecker = window.localStorage.getItem("ptChecker")
        setPtCheckerFlag(ptChecker)
    }, [])
    const handleCancel = () => {
        setisResetPasswdVisible(false);
        setisChangeBindVisible(false);
        setisChangePhoneVisible(false);
    }
    const getCodeFun = async () => {
        let newValues = form.getFieldValue().phone
        let data = {
            phone: newValues
        }
        let res = await getCode(qs.stringify(data))
    }
    const getPhoneCaptcha = () => {
        getCodeFun().then(() => {
            // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
            timeChange.current = setInterval(() => setTime(t => --t), 1000);
            setBtnDisabled(true);
        })
    };
    const getNewPhoneCaptcha = () => {
        getNewCodeFun().then(() => {
            // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
            timeChange.current = setInterval(() => setTime(t => --t), 1000);
            setBtnDisabled(true);
        })
    };
    const getNewCodeFun = async () => {
        let newValues = form.getFieldValue().newPhone
        let data = {
            phone: newValues
        }
        let res = await getCode(qs.stringify(data))
    }
    //绑定新手机号
    const finishChange = () => {
        form.validateFields().then(values => {
            let data = {
                oldPhone: values.oldPhone,
                newPhone: values.newPhone,
                newKaptcha: values.newKaptcha,
            }
            changeAccount(data).then(res => {
                if (res.data.success) {
                    message.success(res.data.message)
                    handleCancel()
                    getOutFun()
                } else {
                    message.warn(res.data.message)
                }
            }).catch(res => {
                message.warn(res.data.message)
            })
        }).catch(errorInfo => {
            console.log(errorInfo)
        })
    };
    const afterClose = () => {
        form.resetFields()
    }
    useEffect(() => {
        let data = window.localStorage.getItem('merchantServeId')
        let param = {
            merchantServeId: data
        }
        getMerchantPhoneInfo(param).then(res => {
            setPhone(res.data.data.phone)
        })
    }, [])
    useEffect(() => {
        clearInterval(timeChange.current);
        return () => clearInterval(timeChange.current);
    }, []);
    useEffect(() => {
        if (time > 0 && time < 60) {
            setBtnContent(`${time}s后重发`);
        } else {
            clearInterval(timeChange.current);
            setBtnContent("获取验证码")
            setBtnDisabled(false);
            setTime(60);
        }
    }, [time]);
    return (
        <div style={{background: '#fefef4', height: '200px', display: 'flex', alignItems: 'center'}}>
            <div style={{width: '500px', marginLeft: '100px', height: '100px'}}>
                <div style={{fontSize: '25px', display: 'flex', justifyContent: 'space-around'}}>
                    <Button style={{background: '#b5de70', color: '#fff', borderRadius: '50%', marginTop: '4px'}}
                            icon={<CheckOutlined/>}></Button>
                    <div style={{color: '#101010'}}>已绑定手机账号:</div>
                    <div style={{color: '#e69524'}}>{phone}</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around', width: '200px', marginLeft: '85px'}}>
                    <Button style={{background: '#fefef4', border: 'none', color: '#46a6ff'}}
                            onClick={openResetPasswd} disabled={ptCheckerFlag == 1 ? true : false}>重置密码</Button>
                    <Button style={{background: '#fefef4', border: 'none', color: '#46a6ff'}}
                            onClick={changeBindPhone} disabled={ptCheckerFlag == 1 ? true : false}>换绑手机</Button>
                </div>
            </div>
            <Modal title="验证手机" visible={isResetPasswdVisible} onOk={handleOk} onCancel={handleCancel}
                   getContainer={false} afterClose={afterClose}
                   okText="确认" cancelText="取消">
                <Form
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="phone"
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input disabled size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="kaptcha"
                        rules={[{required: true, message: '请输入短信验证码!'}]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input size="large" placeholder="请输入验证码"
                               addonAfter={<Button type="link" style={{color: 'black'}} onClick={getPhoneCaptcha}>
                                   {btnContent}
                               </Button>}/>
                    </Form.Item>
                    <Form.Item
                        name="password1"
                        rules={[{required: true, message: '请输入新密码'}]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input.Password placeholder="请输入新密码" size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="password2"
                        rules={[
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value) {
                                        return Promise.reject("请再次输入新密码")
                                    }
                                    if (value || getFieldValue('password1') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject("两次密码输入不一致")
                                }
                            })
                        ]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input.Password placeholder="请再次输入新密码" size="large"/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="换绑手机" visible={isChangeBindVisible} onOk={handleNext} onCancel={handleCancel}
                   getContainer={false} afterClose={afterClose}
                   okText="确认" cancelText="取消">
                <Form
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="phone"
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input disabled size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="kaptcha"
                        rules={[{required: true, message: '请输入短信验证码!'}]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input size="large" placeholder="请输入验证码"
                               addonAfter={<Button type="link" style={{color: 'black'}} onClick={getPhoneCaptcha}>
                                   {btnContent}
                               </Button>}/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="输入新手机号" visible={isChangePhoneVisible} onOk={finishChange} onCancel={handleCancel}
                   getContainer={false} afterClose={afterClose}
                   okText="确认" cancelText="取消">
                <Form
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="oldPhone"
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input disabled size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="newPhone"
                        wrapperCol={{offset: 1, span: 22}}
                        rules={[{required: true, message: '请输入新手机号'}]}
                    >
                        <Input size="large" placeholder="请输入新手机号"/>
                    </Form.Item>
                    <Form.Item
                        name="newKaptcha"
                        rules={[{required: true, message: '请输入短信验证码!'}]}
                        wrapperCol={{offset: 1, span: 22}}
                    >
                        <Input size="large" placeholder="请输入验证码"
                               addonAfter={<Button type="link" style={{color: 'black'}} onClick={getNewPhoneCaptcha}>
                                   {btnContent}
                               </Button>}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
export default BindAccount
